import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import DataTable from 'react-data-table-component';
import backendURL from "../../config";

export default function ListWebsites({ refresh, setRefresh }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fetchedUrl = queryParams.get('fetched_url');

  useEffect(() => {
    document.title = 'Website Lists | KlonIT Web Application';
    fetchDocuments();
  }, [refresh]);

  const [documents, setDocuments] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectionError, setSelectionError] = useState(null);
  const [fetching, setFetching] = useState(false); // State to handle fetching status

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${backendURL}/get-website-links`, { headers });

      const filteredDocuments = response.data.filter(doc => doc.is_scrape === false || doc.is_scrape === 'false' || doc.is_scrape === 'null' || doc.is_scrape === null);
      setDocuments(filteredDocuments);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Authentication error:", error.response.data.error);
      } else {
        setError("Error fetching website:", error.message);
      }
      setLoading(false);
    }
  };

  const handleRowSelected = (state) => {
    if (state.selectedRows.length <= 10) {
      setSelectedDocuments(state.selectedRows);
      setSelectionError(null);
    } else {
      setSelectionError("You can only select up to 10 rows.");
      setToggleCleared(!toggleCleared);
    }
  };

  const handleScrapeDataBTN = async () => {
    setFetching(true); // Set fetching state to true
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      const websiteLinks_id = selectedDocuments.map(doc => doc.id);
      const websiteLinks = selectedDocuments.map(doc => doc.website_link);

      await axios.post(`${backendURL}/add-scrape-data`, { data: [websiteLinks_id, websiteLinks] }, { headers });

      setSelectedDocuments([]);
      setToggleCleared(!toggleCleared);
      setRefresh((prev) => !prev); // Trigger refresh after scraping data
    } catch (error) {
      setError("Error fetching data:", error.message);
    } finally {
      setFetching(false); // Reset fetching state
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = { Authorization: `Bearer ${token}` };

      await axios.delete(`${backendURL}/delete-website-link/${documentId}`, { headers });
      setDocuments(documents.filter(doc => doc.id !== documentId));
      setError("Website link has been deleted successfully.");
    } catch (error) {
      setError("Error deleting Website:", error.message);
    }
  };

  const columns = [    
    {
      name: 'Website Link',
      selector: row => row.website_link,
      sortable: true,
    },
    {
      name: 'Is Scrape?',
      selector: row => row.is_scrape ? "Yes" : "No",
      sortable: true,
    },
    {
      name: 'Create Date',
      selector: row => row.created_date,
      sortable: true,
    },
    {
      name: 'Action',
      cell: row => (
        <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polyline points="3 6 5 6 21 6"></polyline>
            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
          </svg>
        </button>
      ),
    },
  ];

  const contextActions = (
    <div>
      <button onClick={handleScrapeDataBTN} className="btn btn-primary" disabled={fetching}>
        {fetching ? (
          <>
            <i className="fa fa-spinner fa-spin" aria-hidden="true"></i> Fetching Data
          </>
        ) : (
          "Scrape Data"
        )}
      </button>
    </div>
  );

  const handleChange = ({ selectedRows }) => {
    // Limit selection to first 5 rows
    const limitedSelection = selectedRows.slice(0, 5);
    setSelectedDocuments(limitedSelection);
    if (selectedRows.length > 5) {
      setSelectionError("Only the first 5 rows can be selected.");
    } else {
      setSelectionError(null);
    }
  };

  const handleSelectAllChange = (state) => {
    const { selectedCount, selectedRows } = state;
    if (selectedCount > 0) {
      // If selecting all, only select the first 5 rows
      const limitedSelection = documents.slice(0, 5);
      setSelectedDocuments(limitedSelection);
      setSelectionError("Only the first 5 rows can be selected.");
    } else {
      // If deselecting all, clear the selection
      setSelectedDocuments([]);
      setSelectionError(null);
    }
    setToggleCleared(!toggleCleared);
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-header"></div>
          <div className="card-body">
            {fetchedUrl && <div className="alert alert-info">Please select URLs to fetch the data.</div>}

            {selectedDocuments.length > 0 && selectedDocuments.length <= 10 && (
              <div className="mt-3">
                <div className="alert alert-info">
                  <div className="row">
                    <div className="col-6">{selectedDocuments.length} rows selected</div>
                    <div className="col-6 text-end">
                      <button onClick={handleScrapeDataBTN} className="btn btn-primary" disabled={fetching}>
                        {fetching ? (
                          <>
                            Fetching Data <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
                          </>
                        ) : (
                          "Scrape Data"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectionError && <div className="alert alert-danger mt-3">{selectionError}</div>}

            {loading ? (
              <p>Loading...</p>
            ) : documents.length === 0 ? (
              <p>No data available</p>
            ) : (
              <div>
                <DataTable
                  columns={columns}
                  data={documents}
                  pagination
                  selectableRows
                  selectableRowsHighlight
                  onSelectedRowsChange={handleChange}
                  onSelectAllRows={handleSelectAllChange}
                  clearSelectedRows={toggleCleared}
                  contextActions={contextActions}
                />
              </div>
            )}
            <br/>
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div> {/* Display error message */}
            <br/>
          </div>
        </div>
      </div>
    </div>
  );
}