import React, { useState, useEffect } from "react";
import { ChatScriptsURL } from "../../config";
import backendURL from "../../config";
import {APPLICATION_URL} from "../../config";
import axios from "axios";

export default function ChatbotScripts() {
	const [error, setError] = useState(null);
	const [scriptFilename, setScriptFilename] = useState("");
	const [scriptData, setScriptData] = useState(null);

	useEffect(() => {
		document.title = 'Retrain Chatbot | KlonIT Web Application';
		fetchData();
	}, []);

	// Function to fetch data from the API
	const fetchData = async () => {
		try {
			const token = localStorage.getItem('access_token');
			const headers = { Authorization: `Bearer ${token}` };

			// Make the API request
			const response = await axios.post(`${backendURL}/get-javascript`, {}, { headers });
			console.log("Get Data: ", response.data);

			// Save the fetched data to state
			setScriptData(response.data);

		} catch (error) {
			console.error("Full error details:", error);  // Log the full error to console for debugging

			if (error.response) {
				setError(`Error fetching website: ${error.response.data.error || error.response.statusText}`);
			} else if (error.request) {
				setError("No response received from the server. Please try again.");
			} else {
				setError(`Error setting up request: ${error.message}`);
			}
		}
	};

	// Function to update the script with dynamic data
	useEffect(() => {
		if (scriptData) {
			const dynamicScript = `
				<!-- Button to trigger something, for example enabling voice interaction -->
				<button id="voice_enable_btn" type="button" class="cloneme_btn cloneme_btn-secondary cloneme_btn_design2" data-bs-dismiss="modal" style="margin: auto;">Enable Voice Interaction</button>

				<!-- This is where the chatbot HTML content will be loaded -->
				<div id="my-chatbot"></div>

				<script>
					var website_path = "${scriptData.website_path}";
					var gif_img_path = "${APPLICATION_URL}/images/gif/${scriptData.gif_img_path}";
					var png_img_path = "${APPLICATION_URL}/images/avatars/${scriptData.png_img_path}";
					var app_voice_type = "${scriptData.app_voice_type}";

					function loadChatbot(baseUrl) {
						var chatbotContainer = document.getElementById('my-chatbot');

						// Load CSS
						var link = document.createElement('link');
						link.rel = 'stylesheet';
						link.href = baseUrl + '/assets/css/merge.css';
						document.head.appendChild(link);

						// Load Chatbot HTML
						var xhr = new XMLHttpRequest();
						xhr.open('GET', baseUrl + '/master.html', true);
						xhr.onreadystatechange = function () {
							if (xhr.readyState === 4 && xhr.status === 200) {
								chatbotContainer.innerHTML = xhr.responseText;

								// Load merge.js first
								var mergeScript = document.createElement('script');
								mergeScript.src = baseUrl + '/assets/js/merge.js';
								mergeScript.onload = function() {
									console.log('merge.js loaded successfully.');

									// Load other JavaScript files after merge.js
									var otherScripts = [
										baseUrl + '/assets/js/cloneme_language.js',
										baseUrl + '/assets/js/chat.js',
										baseUrl + '/assets/js/chat_history.js',
										baseUrl + '/assets/js/user_feedback.js',
										baseUrl + '/assets/js/voice_chat_enable.js',
										baseUrl + '/assets/js/homepage_message.js'
									];

									otherScripts.forEach(function(src) {
										var script = document.createElement('script');
										script.src = src;
										script.onload = function() {
											console.log(src + ' loaded successfully.');
										};
										script.onerror = function() {
											console.error('Error loading ' + src);
										};
										document.body.appendChild(script);
									});
								};
								mergeScript.onerror = function() {
									console.error('Error loading merge.js');
								};
								document.body.appendChild(mergeScript);
							}
						};
						xhr.send();
					}

					// Example usage
					loadChatbot('${ChatScriptsURL}');
				</script>
			`;

			// Update the script with the dynamic data
			setScriptFilename(dynamicScript);
		}
	}, [scriptData]); // Run this effect when scriptData changes

	// Function to handle copying the script to the clipboard
	const handleCopyClick = () => {
		navigator.clipboard.writeText(scriptFilename)
			.then(() => {
				alert("Script copied to clipboard!");
			})
			.catch((error) => {
				console.error("Copy failed", error);
			});
	};

	return (
		<div>
			<main className="content">
				<div className="container-fluid p-0">					

					{/* New Script Section */}
					<div className="row">
						<div className="col-md-12">
							<h4>Script</h4>
							<div className="card">
								<div className="card-body">
									<div className="form-group">
										<textarea 
											className="form-control" 
											value={scriptFilename} 
											readOnly 
											rows="15"
										/>
										<button 
											className="btn btn-secondary mt-2" 
											onClick={handleCopyClick}
										>
											<i className="fa fa-copy"></i> Copy
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>

				</div>
			</main>
		</div>
	);
}
