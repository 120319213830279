import React from 'react'

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    
    <footer className="footer">
        <div className="container-fluid">
            <div className="row text-muted">
                <div className="col-6 text-start">
                    <p className="mb-0">                        
                        &copy; {year}, <a className="text-muted" href="https://kloneit.net"  rel="noreferrer"><strong>KlonIT</strong></a>. All rights reserved.
                    </p>
                </div>
                <div className="col-6 text-end">
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <a className="text-muted" href="/support"  rel="noreferrer">Support</a>
                        </li>
                        <li className="list-inline-item">
                            <a className="text-muted" href="/help" >Help Center</a>
                        </li>
                        <li className="list-inline-item">
                            <a className="text-muted" href="/privacy" >Privacy</a>
                        </li>
                        <li className="list-inline-item">
                            <a className="text-muted" href="/terms" >Terms</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  )
}
