import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import backendURL, { google_login_api } from "../config";

export default function UserLogin() {

  useEffect(() => {
    document.title = 'Login | KlonIT Web Application';
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post(`${backendURL}/login`, {
        email,
        password,
      });
      
      const { message, access_token, profile_image, fname, lname } = response.data;

      if (access_token) {
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('f_name', fname);
        localStorage.setItem('l_name', lname);
        localStorage.setItem('profile_image', profile_image);
        window.location.replace("/Dashboard");  
      }
      
    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
      } else if (error.request) {
        setError("No response received from API.");
      } else {
        setError("Error setting up request");
      }
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const { credential } = response;

      // Decode the ID token to get user information
      const decodedToken = jwtDecode(credential);
      const { sub, name, given_name, family_name, picture, email, email_verified, locale } = decodedToken;

      const password =  String(sub)

      // console.log("User email:", email);
      // console.log("User password:", password);

      // Send the credential to your backend to get your app's access token
      const googleResponse = await axios.post(`${backendURL}/google-login`, {
        email,
        password,
      });

      const { message, access_token, profile_image, fname, lname } = googleResponse.data;

      if (access_token) {
        localStorage.setItem('access_token', access_token);
        localStorage.setItem('f_name', fname);
        localStorage.setItem('l_name', lname);
        localStorage.setItem('profile_image', profile_image);
        window.location.replace("/Dashboard");
      }

    } catch (error) {
      setError("Google login failed");
    }
  };

  const handleGoogleLoginFailure = (error) => {
    setError("Google login failed");
  };

  return (
    <GoogleOAuthProvider clientId = {google_login_api}>
      <div>
        <main className="d-flex w-100">
          <div className="container d-flex flex-column">
            <div className="row vh-100">
              <div className="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                <div className="d-table-cell align-middle">
                  <div className="text-center mt-4">
                    <h1 className="h2">Welcome back</h1>
                    <p className="lead">Sign in to your account to continue</p>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <div className="m-sm-3">
                        <div className="d-grid gap-2 mb-3">
                          <GoogleLogin
                            onSuccess={handleGoogleLoginSuccess}
                            onFailure={handleGoogleLoginFailure}
                          />
                        </div>
                        <div className="row">
                          <div className="col">
                            <hr />
                          </div>
                          <div className="col-auto text-uppercase d-flex align-items-center">
                            Or
                          </div>
                          <div className="col">
                            <hr />
                          </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                              className="form-control form-control-lg"
                              type="email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              placeholder="Enter your email"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input
                              className="form-control form-control-lg"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              placeholder="Enter your password"
                              required
                            />
                            <small>
                              <a href="/reset-password">Forgot password?</a>
                            </small>
                          </div>
                          <div>
                            <div className="form-check align-items-center">
                              <input
                                id="customControlInline"
                                type="checkbox"
                                className="form-check-input"
                                value="remember-me"
                                checked={rememberMe}
                                onChange={(e) => setRememberMe(e.target.checked)} // Handle onChange for the checkbox
                              />
                              <label
                                className="form-check-label text-small"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className="d-grid gap-2 mt-3">
                            <button className="btn btn-lg btn-primary" type="submit">
                              Sign in
                            </button>
                          </div>
                        </form>
                        <br />
                        <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div> {/* Display error message */}
                      </div>
                      <div className="text-center mb-3">
                        Don't have an account? <a href="/SignUp">Sign up</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </GoogleOAuthProvider>
  );
}
