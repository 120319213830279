import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Privacy() {
  useEffect(() => {
    document.title = "Privacy | KlonIT Web Application";
  }, []);

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle">
              <strong>Privacy</strong>
            </h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="page-Dashboard">
                    <section className="section-Dashboard">
                      <div className="">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xl-12 website-list-group">
                            <div className="list-group">
                                <div className="row g-0 align-items-center">                                  
                                  <div className="col-12 ps-2">
                                    <div className="text-dark">Privacy:</div>
                                    <br/>
                                    <div className="text-muted mt-1">
                                    <p> API means KlonIT application programming interface to be integrated with the User’s software. </p> 

                                    <p> Cookies are small files stored on your Device. </p> 

                                    <p> Device means a computer or a mobile device. </p> 

                                    <p> Data Controller means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data. </p> 

                                    <p> Data Processors (or Service Providers) means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively. </p> 

                                    <p> Data Subject is any living individual who is the subject of Personal Data. </p> 

                                    <p> Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession). </p> 

                                    <p> Service means together the Website, Widget and/or the API (depending on the scope dedicated to a given User). </p> 

                                    <p> Usage Data is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit). </p> 

                                    <p> User is the individual using our Service. User corresponds to the Data Subject, who is the subject of Personal Data. </p> 

                                    <p> Website means web pages located at KlonIT.net. </p> 

                                    <p> Widget means a KlonIT widget that may be implemented to the User’s website. </p> 

                                    </div>                                    
                                  </div>                                  
                                </div>
                            </div>                            
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
