import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";
import ListImproveQuestions from './ListImproveQuestions';

export default function ImproveQuestions() {
  useEffect(() => {
    document.title = "Improve Questions | KlonIT Web Application";
  }, []);

  const [OriginalQuestion, setOriginalQuestion] = useState("");
  const [OriginalAnswer, setOriginalAnswer] = useState("");
  const [QuestionImprove, setQuestionImprove] = useState("");
  const [AnswerImprove, setAnswerImprove] = useState("");
  const [error, setError] = useState(null);
  const [dataRefresh, setDataRefresh] = useState(false);

  const handleAddQuestionBtnClick = async () => {
    
    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const OriginalChatID = "user_chat_id";

      const data = {
        chat_id: OriginalChatID,
        question: OriginalQuestion,
        answer: OriginalAnswer,
        improve_question: QuestionImprove,
        improve_answer: AnswerImprove
      };

      await axios.post(`${backendURL}/add-improve-question`, data, { headers });

      // Clear the input after adding
      setOriginalQuestion("");
      setOriginalAnswer("");
      setQuestionImprove("");
      setAnswerImprove("");

      setError("Question answer has been added successfully.");

      setDataRefresh(true);

    } catch (error) {
        // console.error("Error adding question answer:", error);
        setError("Error adding question answer:", error);
    }
    
  };

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle">              
              <strong>Improve</strong> Question
            </h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0"></h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">User Question</h5>
                      <div className="">
                        <input
                          type="text"
                          id="OriginalQuestion"
                          name="OriginalQuestion"
                          className="form-control"
                          placeholder="User Question"
                          value={OriginalQuestion}
                          onChange={(e) => setOriginalQuestion(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">Bot Answer</h5>
                      <div className="">
                        <input
                          type="text"
                          id="OriginalAnswer"
                          name="OriginalAnswer"
                          className="form-control"
                          placeholder="Bot Answer"
                          value={OriginalAnswer}
                          onChange={(e) => setOriginalAnswer(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">Improve Your Question</h5>
                      <div className="">
                        <input
                          type="text"
                          id="QuestionImprove"
                          name="QuestionImprove"
                          className="form-control"
                          placeholder="Improve your question"
                          value={QuestionImprove}
                          onChange={(e) => setQuestionImprove(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <br/>                  
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <br />
                      <button className="btn btn-primary" onClick={handleAddQuestionBtnClick}> Save </button>
                    </div>
                    <div className="col-12 col-lg-12">                    
                      <br/>
                      <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <ListImproveQuestions dataRefresh={dataRefresh} setDataRefresh={setDataRefresh} />
                    
        </div>
      </main>
    </div>
  );
}
