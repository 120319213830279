import React, { useState, useEffect } from "react";
import reactCSS from 'reactcss'
import { SketchPicker } from 'react-color'
import axios from 'axios';
import backendURL from "../config";

export default function ColorSettings() {
  useEffect(() => {
    document.title = 'Thumbnail and Colors | KlonIT Web Application';
  }, []);

  const [colors, setColors] = useState({});
  const [displayColorPicker, setDisplayColorPicker] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const token = localStorage.getItem("access_token");
    const headers = { Authorization: `Bearer ${token}` };

    axios
      .get(`${backendURL}/get-color-setting`, { headers })
      .then((response) => {
        const data = response.data[0];
        console.log("Get data: ", data);
        setColors(data);
        initializeDisplayColorPicker(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }; 

  const initializeDisplayColorPicker = (data) => {
    const initialDisplayColorPicker = {};
    Object.keys(data).forEach(field => {
      initialDisplayColorPicker[field] = false;
    });
    setDisplayColorPicker(initialDisplayColorPicker);
  };

  const handleClick = (field) => {
    setDisplayColorPicker({ ...displayColorPicker, [field]: !displayColorPicker[field] });
  };

  const handleClose = (field) => {
    setDisplayColorPicker({ ...displayColorPicker, [field]: false });
  };

  const handleChange = (color, field) => {
    const hexColor = rgbToHex([color.rgb.r, color.rgb.g, color.rgb.b]); // Convert color to Hex
    const updatedColors = { ...colors, [field]: hexColor }; // Update the color to Hex format
    setColors(updatedColors);
  };

  const SaveColorSetting = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = { Authorization: `Bearer ${token}` };

      console.log("Get UpdatedColors: ", colors);

      const response = await axios.post(`${backendURL}/update-color-setting`, colors, { headers });
      setError("Data saved successfully");
    } catch (error) {
      setError('Error:', error);
    }
  };

  const rgbToHex = (rgb) => {
    return '#' + ('000000' + ((rgb[0] << 16) | (rgb[1] << 8) | rgb[2]).toString(16)).slice(-6);
  };

  const styles = reactCSS({
    'default': {
      swatch: {
        marginTop: '4px',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  });

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"> <strong>Color </strong> and Position Settings</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-1">Color Style:</h5>
                  <div className="row">
                    {Object.keys(colors).map((field, index) => (
                      <div className="col-4 col-lg-4" key={index}>
                        <div className="row form-group">
                          <div className="col-3">
                            <label className="col-form-label">{field}: </label>
                          </div>
                          <div className="col-3 px-1">
                            {field !== 'position_type' ? (
                              <input
                                type="text"
                                name={field}
                                value={typeof colors[field] === 'object' ? rgbToHex([colors[field].r, colors[field].g, colors[field].b]) : colors[field]} // Convert object to hex if color
                                className="form-control form-control-xsm mt-1"
                              />
                            ) : (
                              <select 
                                className="form-select mb-3"
                                value={colors.position_type} // Set the value here
                                onChange={(e) => setColors({ ...colors, position_type: e.target.value })}
                              >
                                <option value="Left">Left</option>
                                <option value="Right">Right</option>
                                <option value="Middle">Middle</option> {/* Add the Middle option */}
                              </select>
                            )}
                          </div>
                          <div className="col-2 px-1">
                            <div style={styles.swatch} onClick={() => handleClick(field)}>                              
                              <div style={{ width: '22px', height: '22px', borderRadius: '0px', background: `${typeof colors[field] === 'object' ? rgbToHex([colors[field].r, colors[field].g, colors[field].b]) : colors[field]}` }} />
                            </div>
                            {displayColorPicker[field] && (
                              <div style={styles.popover}>
                                <div style={styles.cover} onClick={() => handleClose(field)} />
                                <SketchPicker
                                  color={colors[field]}
                                  onChange={(color) => handleChange(color, field)}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <button type="submit" className="btn btn-primary" onClick={SaveColorSetting}> Save </button> 
                    </div>
                    <br/>
                    <br/>
                    <div className="col-12 col-lg-12">
                      <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
