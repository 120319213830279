import React, { useEffect } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';

const LogoutPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Remove "access_token" from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('f_name');
    localStorage.removeItem('l_name');
    localStorage.removeItem('profile_image');
    // Redirect the user to the login page or any other desired destination
    navigate('/Login');
    <Navigate replace to="/Login" />;

  }, []);

  navigate('/Login');

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"> <strong>Log </strong> Out</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-1">
                    You have successfuly logout. Thanks for visiting.
                  </h5>                  
                </div>
              </div>
            </div>
          </div>          
        </div>
      </main>
    </div>
  );
};

export default LogoutPage;
