import React, { useState, useEffect } from "react";
import axios from "axios";

export default function ChoosePosition() {
  
  useEffect(() => {
    document.title = 'Chatbot Position Setting | KlonIT Web Application';
  }, []);

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"> <strong>Choose</strong> Position</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                
                <div className="card-body">
                  <div className="choose-position-page">
                    <h5 className="card-title mb-1">Choose Position</h5>
                    <div id="selectGender" className="btn-group btn-group-lg mb-3" role="group" aria-label="Large button group">
                        <button type="button" className="btn btn-secondary">Left</button>
                        <button type="button" className="btn btn-secondary">Middle</button>
                        <button type="button" className="btn btn-secondary">Right</button>
                    </div>
                    <br/>
                    <button type="submit" className="btn btn-secondary">Save</button>                        
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </main>
    </div>
  );
}
