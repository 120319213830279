// WebsitePopup.js
import React, { useState } from 'react';

const WebsitePopup = ({ onSubmit, onClose }) => {
    const [webname, setWebname] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(webname);
        onClose();
    };

    return (
        <div>
            <main className="d-flex w-100 h-100">
                <div className="container d-flex flex-column">
                    <div className="row vh-100">
                        <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                            <div className="d-table-cell align-middle">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="m-sm-3">
                                            <div className="popup">
                                                <div className="popup-inner">
                                                    <h2>Step2: Enter your Website Address</h2>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="mb-3">
                                                            <label className="form-label">Website Address</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                value={webname}
                                                                onChange={(e) => setWebname(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="d-grid gap-2 mt-3">
                                                            <button type="submit" className="btn btn-primary">Submit</button>
                                                            <button type="button" className="btn btn-secondary" onClick={onClose}>Back</button>
                                                        </div>                                                        
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    );
};

export default WebsitePopup;
