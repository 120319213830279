import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function BillingPreferences() {

  useEffect(() => {
    document.title = 'Preferences | KlonIT Web Application';
  }, []);

  // State to store selected files
  const [CompanyName, setCompanyName] = useState(null);
  const [POOrder, setPOOrder] = useState(null);
  const [BillingEmail, setBillingEmail] = useState(null);
  const [AddressOne, setAddressOne] = useState(null);
  const [AddressTwo, setAddressTwo] = useState(null);
  const [Country, setCountry] = useState(null);
  const [City, setCity] = useState(null);
  const [State, setState] = useState(null);
  const [Zip, setZip] = useState(null); 

  const [error, setError] = useState(null);

  const handleAddPreferenceBtnClick = async () => {
    
    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const data = {
        company_name: CompanyName,
        po_order: POOrder,
        billing_email: BillingEmail,
        address_one: AddressOne,
        address_two: AddressTwo,
        country: Country,
        city: City,
        state: State,
        zip: Zip
      };

      await axios.post(`${backendURL}/add-billing-preference`, data, { headers });

      // Clear the input after adding
      setCompanyName("");
      setPOOrder("");
      setBillingEmail("");
      setAddressOne(""); 
      setAddressTwo(""); 
      setCountry(""); 
      setCity(""); 
      setState(""); 
      setZip("");

      setError("Payment method has been added successfully.");

      // setDataRefresh(true);

    } catch (error) {
      console.error('Error adding payment method:', error);
      let errorMessage = 'Error adding payment method: ';
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage += error.response.data.message || error.response.statusText;
      } else if (error.request) {
        // The request was made but no response was received
        errorMessage += 'No response received from server';
      } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage += error.message;
      }
      setError(errorMessage);
    }
  };


  return (
    <div>      
      <div className="container-fluid p-0">        
      <div className="row mb-3">
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">Company Name</h5>
                      <div className="">
                        <input
                          type="text"
                          id="CompanyName"
                          name="CompanyName"
                          className="form-control"
                          placeholder="Company Name"
                          value={CompanyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">Purchase Order (PO) Number</h5>
                      <div className="">
                        <input
                          type="text"
                          id="POOrder"
                          name="POOrder"
                          className="form-control"
                          placeholder="Purchase Order (PO)"
                          value={POOrder}
                          onChange={(e) => setPOOrder(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">Billing Email</h5>
                      <div className="">
                        <input
                          type="text"
                          id="BillingEmail"
                          name="BillingEmail"
                          className="form-control"
                          placeholder="Billing email"
                          value={BillingEmail}
                          onChange={(e) => setBillingEmail(e.target.value)}
                        />
                      </div>
                    </div>
                </div>
                <div className="row mb-3">
                    
                    <div className="col-6 col-lg-4">
                      <h5 className="card-title mb-1">Address line 1</h5>
                      <div className="">
                        <input
                          type="text"
                          id="AddressOne"
                          name="AddressOne"
                          className="form-control"
                          placeholder="Address line 1"
                          value={AddressOne}
                          onChange={(e) => setAddressOne(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-4 col-lg-6">
                      <h5 className="card-title mb-1">Address line 2</h5>
                      <div className="">
                        <input
                          type="text"
                          id="AddressTwo"
                          name="AddressTwo"
                          className="form-control"
                          placeholder="Address line 2"
                          value={AddressTwo}
                          onChange={(e) => setAddressTwo(e.target.value)}
                        />
                      </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-3 col-lg-3">
                      <h5 className="card-title mb-1">Country</h5>
                      <div className="">
                        <input
                          type="text"
                          id="Country"
                          name="Country"
                          className="form-control"
                          placeholder="Country"
                          value={Country}
                          onChange={(e) => setCountry(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-3 col-lg-3">
                      <h5 className="card-title mb-1">City</h5>
                      <div className="">
                        <input
                          type="text"
                          id="City"
                          name="City"
                          className="form-control"
                          placeholder="City"
                          value={City}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-3 col-lg-3">
                      <h5 className="card-title mb-1">State</h5>
                      <div className="">
                        <input
                          type="text"
                          id="State"
                          name="State"
                          className="form-control"
                          placeholder="State"
                          value={State}
                          onChange={(e) => setState(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-3 col-lg-3">
                      <h5 className="card-title mb-1">Zip</h5>
                      <div className="">
                        <input
                          type="text"
                          id="Zip"
                          name="Zip"
                          className="form-control"
                          placeholder="Zip"
                          value={Zip}
                          onChange={(e) => setZip(e.target.value)}
                        />
                      </div>
                    </div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-12">
                    <br />
                    <button className="btn btn-primary" onClick={handleAddPreferenceBtnClick}> Save</button>
                  </div>
                  <div className="col-12 col-lg-12">                    
                    <br/>
                    <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
                  </div>
                </div>

      </div>      
    </div>
  );
}
