import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function ListInvoice() {
  useEffect(() => {
    document.title = 'Invoice History | KlonIT Web Application';
    fetchDocuments();
  }, []);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch documents from the API
  const fetchDocuments = async () => {
    try {

      const token = localStorage.getItem('access_token'); // Assuming you're storing the token in localStorage
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const response = await axios.get(`${backendURL}/get-invoice`, { headers });
      setDocuments(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle authentication errors, e.g., redirect to login page
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching data:", error);
        setError("Error fetching data:", error);
      }
    }
  };


  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"> <strong>Billing </strong> History</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-0">History of invoices and billing.</h5>
                  <br />
                  <div className="ListInvoice">
                    {loading ? (
                      <p>Loading...</p>
                    ) : documents.length === 0 ? (
                      <p>No data available</p>
                    ) : (
                      <table id="getDocuments" className="table table-hover my-0">
                        <thead>
                          <tr>
                            <th>Invoice</th>
                            <th>Status</th>
                            <th className="d-none d-xl-table-cell">Amount</th>
                            <th className="d-none d-xl-table-cell">Currency</th>
                            <th className="d-none d-xl-table-cell">Created</th>
                            <th>View Invoice</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents.map((document, index) => (
                            <tr key={index}>
                              <td>{document.invoice_id}</td>
                              <td className="text-capitalize">{document.status}</td>
                              <td className="d-none d-xl-table-cell">{document.amount_paid}</td>
                              <td className="d-none d-xl-table-cell text-uppercase">{document.currency}</td>
                              <td className="d-none d-xl-table-cell">{document.created_date}</td>
                              <td className="form-btn-space">
                                <a target="_blank" href={document.hosted_invoice_url}>View</a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    <br />
                    <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div> {/* Display error message */}
                    <br />
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
