import React, { useState, useEffect, useRef } from 'react';

export default function ChatbotPricing() {
    const [isPricingTableAppended, setIsPricingTableAppended] = useState(false);
    const stripePricingTableRef = useRef(null);

    useEffect(() => {
        document.title = 'Pricing | KlonIT Web Application';

        // Dynamically load the Stripe script
        const script = document.createElement('script');
        script.src = "https://js.stripe.com/v3/pricing-table.js";
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            if (stripePricingTableRef.current && !isPricingTableAppended) {
                // Create and append the stripe-pricing-table element only once
                const stripePricingTable = document.createElement('stripe-pricing-table');
                stripePricingTable.setAttribute('pricing-table-id', 'prctbl_1Pcp9mRrwklCUJlQJ99lUy2F');
                stripePricingTable.setAttribute('publishable-key', 'pk_test_51PaeuvRrwklCUJlQ0UwHfE8pUPB536BjUTF1Hu4cyT1cMTRwSSvKJX5En7mBgi9B33YXVEkKnnhZx8cRsowxDTe200NmRiY9HM');
                stripePricingTableRef.current.appendChild(stripePricingTable);
                setIsPricingTableAppended(true); // Update the state to indicate the table has been appended

                // Observe changes in the stripePricingTableRef to apply styles
                const observer = new MutationObserver(() => {
                    const iframe = stripePricingTableRef.current.querySelector('iframe');
                    if (iframe) {
                        iframe.onload = () => {
                            const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                            const style = document.createElement('style');
                            style.innerHTML = `
                                .PricingTable-grid {
                                    width: 100% !important;
                                }
                            `;
                            iframeDocument.head.appendChild(style);
                        };
                    }
                });

                observer.observe(stripePricingTableRef.current, {
                    childList: true,
                    subtree: true
                });
            }
        };

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };

    }, [isPricingTableAppended]);

    return (
        <div>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="mb-3">
                        <h1 className="h3 d-inline align-middle"> <strong>Our</strong> Pricing</h1>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title mb-0">Choose your pricing plan according to your requirements</h5>
                                    <br/>
                                    <div ref={stripePricingTableRef}></div>
                                </div>
                                <br/>
                            </div>
                        </div>
                    </div>                
                </div>
            </main>
        </div>
    );
}
