import React, {useEffect} from 'react'

export default function BillingOverview() {

    useEffect(() => {
        document.title = 'Billing Overview | KlonIT Web Application';
    }, []);

  return (
    <div>
        <div className="card">
            <div className="card-header">
                <h5 className="card-title mb-0">Overview</h5>
            </div>
            <div className="card-body">
                <div class="settings-page-content">
                    <div>
                        <div class="billing-section billing-plan">
                            <h4>Pay as you go</h4>
                            <h1 class="mt-1 mb-3">$9.88</h1>
                            <div class="mb-3">
                                <div class="alert alert-success alert-dismissible" role="alert">
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    <div class="alert-message">
                                        <h4 class="alert-heading">Auto recharge is off </h4>
                                        <p>When your credit balance reaches $0, your API requests will stop working. Enable automatic recharge to automatically keep your credit balance topped up.</p>
                                        <p><a href="#">Enable auto recharge</a></p>
                                        <hr />
                                        <div class="btn-list">
                                            <a class="btn btn-light" href="/Billing/Subscription">Switch plan</a>
                                            <a class="btn btn-secondary" href="/Billing/Subscription">Cancel plan</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="mt-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <a href="#">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0">
                                                <div class="bg-light rounded-2">
                                                    <div class="icon-item-icon green-gradient-bg"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M2 7a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V7Zm2 4v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-6H4Zm16-2H4V7a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v2Zm-6 6a1 1 0 0 1 1-1h2a1 1 0 1 1 0 2h-2a1 1 0 0 1-1-1Z" clip-rule="evenodd"></path></svg></div>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <strong>Payment methods</strong>
                                                <div class="text-muted">
                                                    Add or change payment method
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-6">
                                    <a href="Billing/Billing-History">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0">
                                                <div class="bg-light rounded-2">
                                                    <div class="icon-item-icon purple-gradient-bg"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M7 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.828a3 3 0 0 0-.879-2.12l-3.828-3.83A3 3 0 0 0 13.172 2H7Zm5 2H7a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-9h-3a3 3 0 0 1-3-3V4Zm5.586 4H15a1 1 0 0 1-1-1V4.414L17.586 8Z" clip-rule="evenodd"></path></svg></div>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <strong>Billing History</strong>
                                                <div class="text-muted">
                                                    View past and current invoices
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                
                            </div>
                        </div>

                        <div class="mt-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <a href="#">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0">
                                                <div class="bg-light rounded-2">
                                                    <div class="icon-item-icon pink-gradient-bg"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M11.568 3.5a1 1 0 0 0-.863.494l-.811 1.381A3.001 3.001 0 0 1 7.33 6.856l-1.596.013a1 1 0 0 0-.858.501l-.439.761a1 1 0 0 0-.004.992l.792 1.4a3 3 0 0 1 0 2.954l-.792 1.4a1 1 0 0 0 .004.992l.439.76a1 1 0 0 0 .858.502l1.596.013a3 3 0 0 1 2.564 1.48l.811 1.382a1 1 0 0 0 .863.494h.87a1 1 0 0 0 .862-.494l.812-1.381a3.001 3.001 0 0 1 2.563-1.481l1.596-.013a1 1 0 0 0 .86-.501l.438-.761a1 1 0 0 0 .004-.992l-.793-1.4a3 3 0 0 1 0-2.954l.793-1.4a1 1 0 0 0-.004-.992l-.439-.76a1 1 0 0 0-.858-.502l-1.597-.013a3 3 0 0 1-2.563-1.48L13.3 3.993a1 1 0 0 0-.862-.494h-.87ZM8.98 2.981A3.001 3.001 0 0 1 11.568 1.5h.87c1.064 0 2.049.564 2.588 1.481l.811 1.382a1 1 0 0 0 .855.494l1.596.013a3 3 0 0 1 2.575 1.502l.44.76a3 3 0 0 1 .011 2.975l-.792 1.4a1 1 0 0 0 0 .985l.792 1.401a3 3 0 0 1-.012 2.974l-.439.761a3.001 3.001 0 0 1-2.575 1.503l-1.597.012a1 1 0 0 0-.854.494l-.811 1.382a3.001 3.001 0 0 1-2.588 1.481h-.87a3.001 3.001 0 0 1-2.588-1.481l-.811-1.382a1 1 0 0 0-.855-.494l-1.596-.012a3.001 3.001 0 0 1-2.576-1.503l-.438-.76a3 3 0 0 1-.013-2.975l.793-1.4a1 1 0 0 0 0-.985l-.793-1.4a3 3 0 0 1 .013-2.975l.438-.761A3.001 3.001 0 0 1 5.718 4.87l1.596-.013a1 1 0 0 0 .855-.494l.81-1.382Z" clip-rule="evenodd"></path><path fill-rule="evenodd" d="M12.003 10.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM8.502 12a3.5 3.5 0 1 1 7 .001 3.5 3.5 0 0 1-7-.001Z" clip-rule="evenodd"></path></svg></div>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <strong>Preferences</strong>
                                                <div class="text-muted">
                                                    Manage billing information
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-6">
                                    <a href="#">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0">
                                                <div class="bg-light rounded-2">
                                                    <div class="icon-item-icon red-gradient-bg"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M14.5 5a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3.874 1a4.002 4.002 0 0 1 7.748 0H20a1 1 0 1 1 0 2h-1.626a4.002 4.002 0 0 1-7.748 0H4a1 1 0 0 1 0-2h6.626ZM9.5 15a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-3.874 1a4.002 4.002 0 0 1 7.748 0H20a1 1 0 1 1 0 2h-6.626a4.002 4.002 0 0 1-7.748 0H4a1 1 0 1 1 0-2h1.626Z" clip-rule="evenodd"></path></svg></div>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <strong>Usage Limits</strong>
                                                <div class="text-muted">
                                                    Set monthly spend limits
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>                                                                                                                                
                            </div>
                        </div>
                        <div className="mt-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <a href="Billing/Pricing">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0">
                                                <div class="bg-light rounded-2">
                                                    <div class="icon-item-icon orange-gradient-bg"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M11.33 5a1 1 0 0 0-1 1v13h3.33V6a1 1 0 0 0-1-1h-1.33Zm4.33 14H18a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-2.34v10Zm0-12V6a3 3 0 0 0-3-3h-1.33a3 3 0 0 0-3 3v5H6a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3h-2.34Zm-7.33 6H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h2.33v-6Z" clip-rule="evenodd"></path></svg></div>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <strong>Pricing</strong>
                                                <div class="text-muted">
                                                    View pricing and FAQs
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-6">
                                    <a href="Billing/Subscription">
                                        <div class="d-flex">
                                            <div class="flex-shrink-0">
                                                <div class="bg-light rounded-2">
                                                    <div class="icon-item-icon orange-gradient-bg"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 24 24"><path fill-rule="evenodd" d="M11.33 5a1 1 0 0 0-1 1v13h3.33V6a1 1 0 0 0-1-1h-1.33Zm4.33 14H18a1 1 0 0 0 1-1v-8a1 1 0 0 0-1-1h-2.34v10Zm0-12V6a3 3 0 0 0-3-3h-1.33a3 3 0 0 0-3 3v5H6a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3v-8a3 3 0 0 0-3-3h-2.34Zm-7.33 6H6a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h2.33v-6Z" clip-rule="evenodd"></path></svg></div>
                                                </div>
                                            </div>
                                            <div class="flex-grow-1 ms-3">
                                                <strong>Subscription</strong>
                                                <div class="text-muted">
                                                    View Subscription History
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
  )
}
