import React, { useState, useEffect } from "react";
import axios from "axios";
import ListDocuments from './ListDocuments';
import { Tabs, Tab } from '@mui/material';
import backendURL from "../../config";

export default function AddDocuments() {
  useEffect(() => {
    document.title = 'Upload Document | KlonIT Web Application';
  }, []);

  const [selectedFiles, setSelectedFiles] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [refresh, setRefresh] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  const handleFileUpload = async () => {
    if (!selectedFiles) {
      alert("Please select file(s) to upload.");
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      formData.append("files", file);
    }

    const token = localStorage.getItem('access_token');

    try {
      const response = await axios.post(`${backendURL}/upload-private-data`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`
        },
      });

      console.log("Upload successful:", response.data);
      setError(response.data.message);
      
      // Trigger refresh after successful upload
      setRefresh(prev => !prev);

    } catch (error) {
      console.error("Error uploading files:", error);
      if (error.response) {
        setError(error.response.data.message);
      }
    }
  };

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"> <strong>Add</strong> Documents</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0">
                    Supported Documents are .txt, .doc, .docx, and .pdf only
                  </h5>
                </div>
                <div className="card-body">
                  <div className="file-loading">
                    <input
                      id="input-20"
                      type="file"
                      multiple
                      accept=".txt,.doc,.docx,.pdf"
                      onChange={handleFileChange}
                    />
                    <button onClick={handleFileUpload}>Upload Files</button>
                  </div>
                </div>
                <br />
                <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
              </div>
            </div>
          </div>

          {/* MUI Tabs for switching between ListDocuments and other components */}
          <Tabs value={activeTab} onChange={handleChange} aria-label="document tabs">
            <Tab label="List Documents" />
            {/* <Tab label="Document Scrape" /> */}
          </Tabs>

          <div className="tab-content">
            {activeTab === 0 && <ListDocuments refresh={refresh} />}
            {/* {activeTab === 1 && <DocumentScrape />} */}
          </div>
        </div>
      </main>
    </div>
  );
}
