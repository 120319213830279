import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../config";

export default function ListUserFeedback() {
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    document.title = 'User Feedback Lists | KlonIT Web Application';
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token'); 
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(`${backendURL}/get-user-feedback`, { headers });
      console.log("Response Data: ", response.data);

      if (response.data && response.data.feedbacks) {
        setDocuments(response.data.feedbacks);
      } else {
        setDocuments([]);
      }

      setLoading(false);
    } catch (error) {
      if (error.response) {
        console.error("Error Response Data: ", error.response.data);
        if (error.response.status === 401) {
          setError("Authentication error: " + error.response.data.error);
        } else {
          setError("Error fetching user feedback: " + error.response.data.error);
        }
      } else {
        console.error("Error: ", error.message);
        setError("Error fetching user feedback");
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <main class="content">
        <div class="mb-3"><h1 class="h3 d-inline align-middle"> <strong>User</strong> Feedbacks</h1></div>
        <div className="row">
          <div className="col-12 col-lg-12 col-xxl-12 d-flex">
            <div className="card flex-fill">
              <div className="card-header">
              </div>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : documents.length === 0 ? (
                  <p>No data available</p>
                ) : (
                  <table id="getDocuments" className="table table-hover my-0">
                    <thead>
                      <tr>
                      <th className="d-none d-xl-table-cell">Questions</th>
                        <th className="d-none d-xl-table-cell">Answers</th>
                        <th>User Issue</th>
                        <th className="d-none d-xl-table-cell">User Feedback</th>                  
                        <th className="d-none d-xl-table-cell">Create Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documents.map((document, index) => (
                        <tr key={index}>
                          <td className="d-none d-xl-table-cell">{document.questions}</td>
                          <td className="d-none d-xl-table-cell">{document.answers}</td>
                          <td>{document.user_issue}</td>
                          <td className="d-none d-xl-table-cell">{document.user_feedback}</td>                
                          <td className="d-none d-xl-table-cell">{document.created_date}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
                <br/>
                <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
                <br/>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
