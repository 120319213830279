// config.js

// We have two ENV's like "Production" and "Development"
const ENV = "Production";

let backendURL;
let ChatScriptsURL;
let APPLICATION_URL;

// Development
if (ENV === "Development") {
    backendURL = "http://127.0.0.1:5000";
    ChatScriptsURL = "http://localhost/klonit-ui/demos";
    APPLICATION_URL = "http://localhost:3000";
}

// Production
if (ENV === "Production") {
    backendURL = "https://klonit-api-backend.f6qcwr.easypanel.host";
    ChatScriptsURL = "https://demos.klonit.net";
    APPLICATION_URL = "https://dashboard.klonit.net";
}

// Google API
const google_login_api = "466151718035-jofm6ggf2c637pvnu3h50ajsmkpl4as9.apps.googleusercontent.com";

export default backendURL;
export { google_login_api, ChatScriptsURL, APPLICATION_URL };