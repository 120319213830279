// rfc

import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

export default function Header({ toggleSidebar }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleNotification = () => {
    setIsNotificationOpen(!isNotificationOpen);
  };

  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [profileImg, setProfileImg] = useState('');

  useEffect(() => {
    // Retrieve data from localStorage
    const storedFname = localStorage.getItem('f_name');
    const storedLname = localStorage.getItem('l_name');
    const storedProfileImg = localStorage.getItem('profile_image');

    // Update state with retrieved data
    if (storedFname) setFname(storedFname);
    if (storedLname) setLname(storedLname);
    if (storedProfileImg) setProfileImg(storedProfileImg);
  }, []);

  return (
    <nav className="navbar navbar-expand navbar-light navbar-bg">
      <button className="sidebar-toggle js-sidebar-toggle EditIcon" onClick={toggleSidebar} >
        <i className="hamburger align-self-center"></i>
      </button>

      <div className="navbar-collapse collapse">
        <ul className="navbar-nav navbar-align">
          {/* <li className="nav-item dropdown">
            <Link
              className={`nav-icon dropdown-toggle ${isNotificationOpen ? "show" : ""}`}
              to="#"
              id="alertsDropdown"
              data-bs-toggle="dropdown"
              onClick={toggleNotification}
            >
              <div className="position-relative">
                <i className="align-middle" data-feather="bell"></i>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell align-middle"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                <span className="indicator">4</span>
              </div>
            </Link>
            <div
              className={`dropdown-menu dropdown-menu-lg dropdown-menu-end py-0 ${isNotificationOpen ? "show" : ""}`}
              aria-labelledby="alertsDropdown"
            >
              <div className="dropdown-menu-header">4 New Notifications</div>
              <div className="list-group">
                <Link to="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell align-middle me-2"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Update completed</div>
                      <div className="text-muted small mt-1">
                        Restart server 12 to complete the update.
                      </div>
                      <div className="text-muted small mt-1">30m ago</div>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell align-middle me-2"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Lorem ipsum</div>
                      <div className="text-muted small mt-1">
                        Aliquam ex eros, imperdiet vulputate hendrerit et.
                      </div>
                      <div className="text-muted small mt-1">2h ago</div>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell align-middle me-2"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                    </div>
                    <div className="col-10">
                      <div className="text-dark">Login from 192.186.1.8</div>
                      <div className="text-muted small mt-1">5h ago</div>
                    </div>
                  </div>
                </Link>
                <Link to="#" className="list-group-item">
                  <div className="row g-0 align-items-center">
                    <div className="col-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell align-middle me-2"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
                    </div>
                    <div className="col-10">
                      <div className="text-dark">New connection</div>
                      <div className="text-muted small mt-1">
                        Christina accepted your request.
                      </div>
                      <div className="text-muted small mt-1">14h ago</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="dropdown-menu-footer">
                <Link to="all-notifications" className="text-muted">
                  Show all notifications
                </Link>
              </div>
            </div>
          </li> */}
          <li className="nav-item dropdown">
            <Link
              className="nav-icon dropdown-toggle d-inline-block d-sm-none"
              to="/"
              data-bs-toggle="dropdown"
            >
              <i className="align-middle" data-feather="settings"></i>
            </Link>

            <Link
              className={`nav-link dropdown-toggle d-none d-sm-inline-block ${
                isDropdownOpen ? "show" : ""
              }`}
              to="#"
              data-bs-toggle="dropdown"
              onClick={toggleDropdown}
            >
              <img
                src={profileImg}
                className="avatar img-fluid rounded me-1"
                alt={`${fname} ${lname}`}
              />{" "}
              <span className="text-dark">{fname} {lname}</span>
            </Link>
            <div
              className={`dropdown-menu dropdown-menu-end ${
                isDropdownOpen ? "show" : ""
              }`}
            >
              <Link className="dropdown-item" to="user-profile">
                <i className="align-middle me-1" data-feather="user"></i>{" "}
                Profile
              </Link>
              <Link className="dropdown-item" to="/Billing">
                <i className="align-middle me-1" data-feather="pie-chart"></i>
                Billing
              </Link>
              <div className="dropdown-divider"></div>
              {/* <Link className="dropdown-item" to="privacy">
                <i className="align-middle me-1" data-feather="settings"></i>{" "}
                Settings & Privacy
              </Link> */}
              <Link className="dropdown-item" to="help">
                <i className="align-middle me-1" data-feather="help-circle"></i>{" "}
                Help Center
              </Link>
              <div className="dropdown-divider"></div>
              <Link className="dropdown-item" to="log-out">
                Log out
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
}
