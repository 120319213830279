import React, {useEffect} from 'react'

export default function Analytics() {

    useEffect(() => {
        document.title = 'Analytics | KlonIT Web Application';
    }, []);

  return (
    <main className="content">
        <div className="container-fluid p-0">

            <h1 className="h3 mb-3"><strong>Analytics</strong> Dashboard</h1>

            <div className="row">
                <div className="col-xl-6 col-xxl-5 d-flex">
                    <div className="w-100">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Sales</h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-truck align-middle"><rect x="1" y="3" width="15" height="13"></rect><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon><circle cx="5.5" cy="18.5" r="2.5"></circle><circle cx="18.5" cy="18.5" r="2.5"></circle></svg>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="mt-1 mb-3">2.382</h1>
                                        <div className="mb-0">
                                            <span className="text-danger"> <i className="mdi mdi-arrow-bottom-right"></i> -3.65% </span>
                                            <span className="text-muted">Since last week</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Visitors</h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-users align-middle"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="mt-1 mb-3">14.212</h1>
                                        <div className="mb-0">
                                            <span className="text-success"> <i className="mdi mdi-arrow-bottom-right"></i> 5.25% </span>
                                            <span className="text-muted">Since last week</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Earnings</h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-dollar-sign align-middle"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="mt-1 mb-3">$21.300</h1>
                                        <div className="mb-0">
                                            <span className="text-success"> <i className="mdi mdi-arrow-bottom-right"></i> 6.65% </span>
                                            <span className="text-muted">Since last week</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col mt-0">
                                                <h5 className="card-title">Orders</h5>
                                            </div>

                                            <div className="col-auto">
                                                <div className="stat text-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-shopping-cart align-middle"><circle cx="9" cy="21" r="1"></circle><circle cx="20" cy="21" r="1"></circle><path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path></svg>
                                                </div>
                                            </div>
                                        </div>
                                        <h1 className="mt-1 mb-3">64</h1>
                                        <div className="mb-0">
                                            <span className="text-danger"> <i className="mdi mdi-arrow-bottom-right"></i> -2.25% </span>
                                            <span className="text-muted">Since last week</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-xl-6 col-xxl-7">
                    <div className="card flex-fill w-100">
                        <div className="card-header">

                            <h5 className="card-title mb-0">Recent Movement</h5>
                        </div>
                        <div className="card-body py-3">
                            <div className="chart chart-sm">
                                <canvas id="chartjs-dashboard-line"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6 col-xxl-3 d-flex order-2 order-xxl-3">
                    <div className="card flex-fill w-100">
                        <div className="card-header">

                            <h5 className="card-title mb-0">Browser Usage</h5>
                        </div>
                        <div className="card-body d-flex">
                            <div className="align-self-center w-100">
                                <div className="py-3">
                                    <div className="chart chart-xs">
                                        <canvas id="chartjs-dashboard-pie"></canvas>
                                    </div>
                                </div>

                                <table className="table mb-0">
                                    <tbody>
                                        <tr>
                                            <td>Chrome</td>
                                            <td className="text-end">4306</td>
                                        </tr>
                                        <tr>
                                            <td>Firefox</td>
                                            <td className="text-end">3801</td>
                                        </tr>
                                        <tr>
                                            <td>IE</td>
                                            <td className="text-end">1689</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-12 col-xxl-6 d-flex order-3 order-xxl-2">
                    <div className="card flex-fill w-100">
                        <div className="card-header">

                            <h5 className="card-title mb-0">Real-Time</h5>
                        </div>
                        <div className="card-body px-4">
                            <div id="world_map" style={{height: '350px' }}></div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-xxl-3 d-flex order-1 order-xxl-1">
                    <div className="card flex-fill">
                        <div className="card-header">

                            <h5 className="card-title mb-0">Calendar</h5>
                        </div>
                        <div className="card-body d-flex">
                            <div className="align-self-center w-100">
                                <div className="chart">
                                    <div id="datetimepicker-dashboard"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-8 col-xxl-9 d-flex">
                    <div className="card flex-fill">
                        <div className="card-header">

                            <h5 className="card-title mb-0">Latest Projects</h5>
                        </div>
                        <table className="table table-hover my-0">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className="d-none d-xl-table-cell">Start Date</th>
                                    <th className="d-none d-xl-table-cell">End Date</th>
                                    <th>Status</th>
                                    <th className="d-none d-md-table-cell">Assignee</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Project Apollo</td>
                                    <td className="d-none d-xl-table-cell">01/01/2021</td>
                                    <td className="d-none d-xl-table-cell">31/06/2021</td>
                                    <td><span className="badge bg-success">Done</span></td>
                                    <td className="d-none d-md-table-cell">Vanessa Tucker</td>
                                </tr>
                                <tr>
                                    <td>Project Fireball</td>
                                    <td className="d-none d-xl-table-cell">01/01/2021</td>
                                    <td className="d-none d-xl-table-cell">31/06/2021</td>
                                    <td><span className="badge bg-danger">Cancelled</span></td>
                                    <td className="d-none d-md-table-cell">William Harris</td>
                                </tr>
                                <tr>
                                    <td>Project Hades</td>
                                    <td className="d-none d-xl-table-cell">01/01/2021</td>
                                    <td className="d-none d-xl-table-cell">31/06/2021</td>
                                    <td><span className="badge bg-success">Done</span></td>
                                    <td className="d-none d-md-table-cell">Sharon Lessman</td>
                                </tr>
                                <tr>
                                    <td>Project Nitro</td>
                                    <td className="d-none d-xl-table-cell">01/01/2021</td>
                                    <td className="d-none d-xl-table-cell">31/06/2021</td>
                                    <td><span className="badge bg-warning">In progress</span></td>
                                    <td className="d-none d-md-table-cell">Vanessa Tucker</td>
                                </tr>
                                <tr>
                                    <td>Project Phoenix</td>
                                    <td className="d-none d-xl-table-cell">01/01/2021</td>
                                    <td className="d-none d-xl-table-cell">31/06/2021</td>
                                    <td><span className="badge bg-success">Done</span></td>
                                    <td className="d-none d-md-table-cell">William Harris</td>
                                </tr>
                                <tr>
                                    <td>Project X</td>
                                    <td className="d-none d-xl-table-cell">01/01/2021</td>
                                    <td className="d-none d-xl-table-cell">31/06/2021</td>
                                    <td><span className="badge bg-success">Done</span></td>
                                    <td className="d-none d-md-table-cell">Sharon Lessman</td>
                                </tr>
                                <tr>
                                    <td>Project Romeo</td>
                                    <td className="d-none d-xl-table-cell">01/01/2021</td>
                                    <td className="d-none d-xl-table-cell">31/06/2021</td>
                                    <td><span className="badge bg-success">Done</span></td>
                                    <td className="d-none d-md-table-cell">Christina Mason</td>
                                </tr>
                                <tr>
                                    <td>Project Wombat</td>
                                    <td className="d-none d-xl-table-cell">01/01/2021</td>
                                    <td className="d-none d-xl-table-cell">31/06/2021</td>
                                    <td><span className="badge bg-warning">In progress</span></td>
                                    <td className="d-none d-md-table-cell">William Harris</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-12 col-lg-4 col-xxl-3 d-flex">
                    <div className="card flex-fill w-100">
                        <div className="card-header">

                            <h5 className="card-title mb-0">Monthly Sales</h5>
                        </div>
                        <div className="card-body d-flex w-100">
                            <div className="align-self-center chart chart-lg">
                                <canvas id="chartjs-dashboard-bar"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </main>
  )
}
