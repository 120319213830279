import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function ListSubscription() {
  useEffect(() => {
    document.title = 'Subscriptions | KlonIT Web Application';
    fetchDocuments();
  }, []);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch documents from the API
  const fetchDocuments = async () => {
    try {

      const token = localStorage.getItem('access_token'); // Assuming you're storing the token in localStorage
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const response = await axios.get(`${backendURL}/get-subscription`, { headers });
      setDocuments(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle authentication errors, e.g., redirect to login page
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching data:", error);
        setError("Error fetching data:", error);
      }
    }
  };


  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"> <strong>Subscription </strong> History</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-0">History of subscription.</h5>
                  <br />
                  <div className="ListSubscription">
                    {loading ? (
                      <p>Loading...</p>
                    ) : documents.length === 0 ? (
                      <p>No data available</p>
                    ) : (
                      <table id="getDocuments" className="table table-hover my-0">
                        <thead>
                          <tr>
                            <th>Subscription</th>
                            <th>Collection Method</th>
                            <th className="d-none d-xl-table-cell">Currency</th>
                            <th className="d-none d-xl-table-cell">Subscription Start</th>
                            <th className="d-none d-xl-table-cell">Subscription End</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents.map((document, index) => (
                            <tr key={index}>
                              <td>{document.subscription_id}</td>
                              <td className="text-capitalize">{document.collection_method}</td>
                              <td className="d-none d-xl-table-cell text-uppercase">{document.currency}</td>
                              <td className="d-none d-xl-table-cell text-uppercase">{document.current_period_start}</td>
                              <td className="d-none d-xl-table-cell">{document.current_period_end}</td>
                              <td className="form-btn-space">
                                <a className="btn btn-primary btn-sm" target="_blank" href={document.url}>View plan</a>
                                <a className="btn btn-primary btn-sm" target="_blank" href="/Billing/Pricing">Upgrade plan</a>
                                <a className="btn btn-primary btn-sm" target="_blank" href="/Billing/Pricing">Cancel plan</a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    <br />
                    <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div> {/* Display error message */}
                    <br />
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
