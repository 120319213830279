import React, { useState } from "react";
import axios from "axios";
import backendURL, { google_login_api } from "../config";
import { useNavigate } from "react-router-dom";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from "jwt-decode"; // Correct import statement
import WebsitePopup from './WebsitePopup'; // Import the WebsitePopup component

// Define custom hook to handle redirect
const useRedirectToLogin = () => {
  const history = useNavigate();

  const redirectToLogin = () => {
    history("/Login");
  };

  return { redirectToLogin };
};

export default function UserSignUp() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    webname: ""
  });

  const [error, setError] = useState(null);
  const { redirectToLogin } = useRedirectToLogin();
  const [showPopup, setShowPopup] = useState(false);
  const [googleFormData, setGoogleFormData] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sleep = ms => new Promise(r => setTimeout(r, ms));
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData); // Log formData to check its structure and values signup

    try {
      const response = await axios.post(`${backendURL}/signup`, formData);
      console.log(response.data);
      setError("The account has been created.");
      await sleep(2000);

      // Redirect to the Login page after waiting for 2 seconds
      redirectToLogin();

      // Handle success, redirect user, show message, etc.
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        setError(error.response.data.message);
        // Handle error, show error message, etc.
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
        setError("No response received");
        // Handle error, show error message, etc.
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up request:", error.message);
        setError("Error setting up request");
        // Handle error, show error message, etc.
      }
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const { credential } = response;

      // Decode the ID token to get user information
      const decodedToken = jwtDecode(credential);
      const { sub, name, given_name, family_name, picture, email, email_verified, locale } = decodedToken;

      // console.log("Signup User ID:", sub);
      // console.log("Signup User name:", name);
      // console.log("Signup User first name:", given_name);
      // console.log("Signup User last name:", family_name);
      // console.log("Signup User picture URL:", picture);
      // console.log("Signup User email:", email);
      // console.log("Signup User email verified:", email_verified);
      // console.log("Signup User locale:", locale);

      // Store the form data and show the popup
      setGoogleFormData({
        email: email,
        password: sub,
        given_name: given_name,
        family_name: family_name,
        picture: picture,
      });
      setShowPopup(true);

    } catch (error) {
      setError("Google signup failed!");
    }
  };

  const handleGoogleLoginFailure = (error) => {
    setError("Google signup failed.");
  };

  const handlePopupSubmit = async (webname) => {
    if (!googleFormData) return;

    const googleFormDataWithWebname = new FormData();
    googleFormDataWithWebname.append('email', googleFormData.email);
    googleFormDataWithWebname.append('password', googleFormData.password);

    googleFormDataWithWebname.append('first_name', googleFormData.given_name);
    googleFormDataWithWebname.append('last_name', googleFormData.family_name);
    googleFormDataWithWebname.append('profile_pic', googleFormData.picture);

    googleFormDataWithWebname.append('webname', webname);

    // console.log("Get Google Email: ", googleFormData.email)
    // console.log("Get Google Password: ", googleFormData.password)
    // console.log("Get Google Webname: ", webname)
    // console.log("Get Google googleFormDataWithWebname: ", googleFormDataWithWebname)

    try {
      const response = await axios.post(`${backendURL}/google-signup`, googleFormDataWithWebname, {
        headers: {
          'Content-Type': 'application/json' // Set the Content-Type header
        }
      });

      console.log(response.data);
      setError("The account has been created.");
      await sleep(2000);

      // Redirect to the Login page after waiting for 2 seconds
      redirectToLogin();

    } catch (error) {
      if (error.response) {
        setError(error.response.data.message);
        console.log("Google Signup Error: ", error.response.data.message);
      } else if (error.request) {
        console.error("No response received:", error.request);
        setError("No response received");
      } else {
        console.error("Error setting up request:", error.message);
        setError("Error setting up request");
      }
    }
  };

  return (
    <GoogleOAuthProvider clientId={google_login_api}>
      <div>
        <main className={`d-flex w-100 h-100 ${showPopup ? 'd-none' : ''}`}>
          <div className="container d-flex flex-column">
            <div className="row vh-100">
              <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 mx-auto d-table h-100">
                <div className="d-table-cell align-middle">
                  <div className="text-center mt-4">
                    <h1 className="h2">Get started</h1>
                    <p className="lead">
                      Start creating the best possible user experience for you customers.
                    </p>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      <div className="m-sm-3">
                        <GoogleLogin
                          onSuccess={handleGoogleLoginSuccess}
                          onFailure={handleGoogleLoginFailure}
                          text="signup_with"
                          size="large"
                        />

                        <div className="row">
                          <div className="col">
                            <hr />
                          </div>
                          <div className="col-auto text-uppercase d-flex align-items-center">
                            Or
                          </div>
                          <div className="col">
                            <hr />
                          </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input className="form-control form-control-lg" type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input className="form-control form-control-lg" type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Enter password" />
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Your Website</label>
                            <input className="form-control form-control-lg" type="text" name="webname" value={formData.webname} onChange={handleChange} placeholder="Enter your website" />
                          </div>
                          <div className="d-grid gap-2 mt-3">
                            <button type="submit" className="btn btn-lg btn-primary">Sign up</button>
                          </div>
                        </form>
                        <br />
                        <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mb-3">
                    Already have account? <a href="/Login">Log In</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>

      {showPopup && (
        <WebsitePopup
          onSubmit={handlePopupSubmit}
          onClose={() => setShowPopup(false)}
        />
      )}
    </GoogleOAuthProvider>
  );
}
