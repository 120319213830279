import React, { useEffect, useState } from "react";
import axios from "axios";
import backendURL from "../config";

export default function WebsiteConfigurations() {
  const [websiteConfigurations, setWebsiteConfigurations] = useState([]);
  const [websiteAddress, setWebsiteAddress] = useState("");
  const [websiteDescription, setWebsiteDescription] = useState("");
  const [websiteSitemap, setWebsiteSitemap] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const token = localStorage.getItem("access_token");
  // Set the headers with the token
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const fetchData = () => {
    axios
      .get(`${backendURL}/get_website_configuration`, { headers })
      .then((response) => {
        setWebsiteConfigurations(response.data);
        // console.error("Getting response", response.data);
        if (response.data.length > 0) {
          const latestConfig = response.data[response.data.length - 1];
          setWebsiteAddress(latestConfig.website_address);
          setWebsiteDescription(latestConfig.website_description);
          setWebsiteSitemap(latestConfig.website_sitemap);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSaveButtonClick = () => {

    // Get the token from localStorage
    const token = localStorage.getItem("access_token");
    // console.log("Config token: ", token)

    // Prepare data to be sent in the request body
    const data = {
      website_address: document.getElementById("wAddress").value,
      website_description: websiteDescription,
      website_sitemap: websiteSitemap
    };

    // Set the headers with the token
    const headers = {
      Authorization: `Bearer ${token}`
    };

    // Send a POST request to update the website configuration
    axios
      .post(`${backendURL}/update_website_configuration`, data, { headers })
      .then((response) => {
        // Handle success response
        setMessage(response.data.message);
        console.log("Success: ",response.data.message);
      })
      .catch((error) => {
        // Handle error response
        if (error.response) {
          setMessage(error.response.data.message);
          console.log("Error1: ",error.response.data.message);
        } else if (error.request) {
          setMessage(error.request);
          console.log("Error2: ",error.request);
      } else {
          setMessage(error.message);
          console.log("Error3: ",error.message);
      }

      });
  };

  return (
    <div>
      <main className="content page-website-configurations">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"> <strong>Website</strong> Configurations</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">
                        Website Address:
                      </h5>
                      <div className="">
                        <input type="text" id="wAddress" name="wAddress" className="form-control" value={websiteAddress} onChange={(e) => setWebsiteAddress(e.target.value)} readOnly disabled />                        
                      </div>
                    </div>
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">
                        Website Description:
                      </h5>
                      <div className="">
                        <input type="text" id="wDescription" name="wDescription" className="form-control" value={websiteDescription} onChange={(e) => setWebsiteDescription(e.target.value)} />                        
                      </div>
                    </div>
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">
                        Website Sitemap:
                      </h5>
                      <div className="">
                        <input type="text" id="wSitemap" name="wSitemap" className="form-control" value={websiteSitemap} onChange={(e) => setWebsiteSitemap(e.target.value)} />                        
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-12">
                        <br />
                        <button className="btn btn-primary" onClick={handleSaveButtonClick}> Save </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-lg-12">
                        <br />
                        <div id="getError" className={`alert alert-info ${message ? "" : "d-none"}`}>{message}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
