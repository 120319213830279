import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function ListAudioLanguage() {
  useEffect(() => {
    document.title = 'Audio & Language Settings | KlonIT Web Application';
    fetchDocuments();
  }, []);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch documents from the API
  const fetchDocuments = async () => {
    try {

      const token = localStorage.getItem('access_token'); // Assuming you're storing the token in localStorage
      // Set the headers with the token
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const response = await axios.get(`${backendURL}/get-language-audio`, { headers });
      setDocuments(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle authentication errors, e.g., redirect to login page
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching data:", error);
        setError("Error fetching data:", error);
      }
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        Authorization: `Bearer ${token}`
      };

      await axios.delete(`${backendURL}/delete-language-audio/${documentId}`, { headers });
      // Remove the deleted document from the local state
      setDocuments(documents.filter(doc => doc.id !== documentId));
      setError("Data has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting data:", error);
      setError("Error deleting data:", error);
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-header">
            {/* <h5 className="card-title mb-0">Uploaded Files</h5> */}
          </div>
          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : documents.length === 0 ? (
              <p>No data available</p>
            ) : (
              <table id="getDocuments" className="table table-hover my-0">
                <thead>
                  <tr>
                    <th>Language</th>
                    <th className="d-none d-xl-table-cell">Gender</th>
                    <th className="d-none d-xl-table-cell">Voice</th>
                    <th className="d-none d-xl-table-cell">Default Language</th>
                    <th className="d-none d-xl-table-cell">Create Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((document, index) => (
                    <tr key={index}>
                      <td>{document.website_lang}</td>
                      <td className="d-none d-xl-table-cell">{document.website_gender}</td>
                      <td className="d-none d-xl-table-cell">{document.website_voice}</td>
                      <td className="d-none d-xl-table-cell">{document.default_language ? "Yes" : "No"}</td>
                      <td className="d-none d-xl-table-cell">{document.created_date}</td>
                      <td className="form-btn-space">
                        <button className="deleteIcon" onClick={() => handleDelete(document.id)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            <br/>
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
            <br/>
          </div>
        </div>
      </div>
    </div>
  );
}
