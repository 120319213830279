import React, { useState } from 'react';
import 'font-awesome/css/font-awesome.min.css';

function Pronoun() {
  const [leftOptions, setLeftOptions] = useState([
    { value: '1', label: 'Item 1', selected: false },
    { value: '2', label: 'Item 5', selected: false },
    { value: '3', label: 'Item 2', selected: false },
    { value: '4', label: 'Item 4', selected: false },
    { value: '5', label: 'Item 3', selected: false }
  ]);
  const [rightOptions, setRightOptions] = useState([]);

  const moveOption = (sourceOptions, targetOptions, optionToMove) => {
    const updatedSourceOptions = sourceOptions.filter(option => option.value !== optionToMove.value);
    const updatedTargetOptions = [...targetOptions, optionToMove];
    return [updatedSourceOptions, updatedTargetOptions];
  };

  const moveSelectedOptions = (sourceOptions, targetOptions) => {
    const selectedOptions = sourceOptions.filter(option => option.selected);
    const updatedOptions = selectedOptions.reduce((acc, curr) => {
      const [updatedSourceOptions, updatedTargetOptions] = moveOption(sourceOptions, targetOptions, curr);
      sourceOptions = updatedSourceOptions;
      targetOptions = updatedTargetOptions;
      return targetOptions;
    }, targetOptions);
    return [sourceOptions, updatedOptions];
  };

  const moveAllOptions = (sourceOptions, targetOptions) => {
    const updatedTargetOptions = [...targetOptions, ...sourceOptions];
    return [[], updatedTargetOptions];
  };

  const handleMoveRightSelected = () => {
    const [updatedLeftOptions, updatedRightOptions] = moveSelectedOptions(leftOptions, rightOptions);
    setLeftOptions(updatedLeftOptions.map(option => ({ ...option, selected: false })));
    setRightOptions(updatedRightOptions);
  };

  const handleMoveLeftSelected = () => {
    const [updatedRightOptions, updatedLeftOptions] = moveSelectedOptions(rightOptions, leftOptions);
    setRightOptions(updatedRightOptions.map(option => ({ ...option, selected: false })));
    setLeftOptions(updatedLeftOptions);
  };

  const handleMoveAllRight = () => {
    const [updatedLeftOptions, updatedRightOptions] = moveAllOptions(leftOptions, rightOptions);
    setLeftOptions(updatedLeftOptions);
    setRightOptions(updatedRightOptions);
  };

  const handleMoveAllLeft = () => {
    const [updatedRightOptions, updatedLeftOptions] = moveAllOptions(rightOptions, leftOptions);
    setRightOptions(updatedRightOptions);
    setLeftOptions(updatedLeftOptions);
  };

  const handleLeftSelectChange = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
    setLeftOptions(
      leftOptions.map(option =>
        selectedValues.includes(option.value)
          ? { ...option, selected: true }
          : { ...option, selected: false }
      )
    );
  };

  const handleRightSelectChange = (event) => {
    const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
    setRightOptions(
      rightOptions.map(option =>
        selectedValues.includes(option.value)
          ? { ...option, selected: true }
          : { ...option, selected: false }
      )
    );
  };

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle"><strong>Pro</strong> Nouns</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-5">
                      <select
                        name="from[]"
                        className="form-control multiselectSize"
                        size="8"
                        multiple="multiple"
                        value={leftOptions.filter(option => option.selected).map(option => option.value)}
                        onChange={handleLeftSelectChange}
                      >
                        {leftOptions.map(option => (
                          <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>

                    <div className="col-lg-1 pronoun-icons mt-4">
                      <button type="button" className="btn btn-primary" onClick={handleMoveAllRight}><i className="fa fa-forward"></i></button>
                      <button type="button" className="btn btn-primary" onClick={handleMoveRightSelected}><i className="fa fa-chevron-right"></i></button>
                      <button type="button" className="btn btn-primary" onClick={handleMoveLeftSelected}><i className="fa fa-chevron-left"></i></button>
                      <button type="button" className="btn btn-primary" onClick={handleMoveAllLeft}><i className="fa fa-backward"></i></button>
                    </div>

                    <div className="col-lg-5">
                      <select
                        name="to[]"
                        className="form-control multiselectSize"
                        size="8"
                        multiple="multiple"
                        value={rightOptions.filter(option => option.selected).map(option => option.value)}
                        onChange={handleRightSelectChange}
                      >
                        {rightOptions.map(option => (
                          <option key={option.value} value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Pronoun;
