import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import backendURL from "../../config";
import ListAudioLanguage from './ListAudioLanguage';

function AudioLanguage() {
  useEffect(() => {
    document.title = "Audio & Language Settings | KlonIT Web Application";
  }, []);

  const LanguageSelection = [
    { value: "af-ZA", label: "Afrikaans" },
    { value: "ar-XA", label: "Arabic" },
    { value: "bn-IN", label: "Bengali" },
    { value: "bg-BG", label: "Bulgarian " },
    { value: "cs-CZ", label: "Czech" },
    { value: "da-DK", label: "Danish" },
    { value: "nl-NL", label: "Dutch" },
    { value: "en-AU", label: "English (Australia)" },
    { value: "en-GB", label: "English (UK)" },
    { value: "en-US", label: "English (US)" },
    { value: "fil-PH", label: "Filipino" },
    { value: "fi-FI", label: "Finnish" },
    { value: "fr-FR", label: "French" },
    { value: "de-DE", label: "German" },
    { value: "el-GR", label: "Greek" },
    { value: "gu-IN", label: "Gujarati" },
    { value: "he-IL", label: "Hebrew" },
    { value: "hi-IN", label: "Hindi" },
    { value: "hu-HU", label: "Hungarian" },
    { value: "is-IS", label: "Icelandic" },
    { value: "id-ID", label: "Indonesian" },
    { value: "it-IT", label: "Italian" },
    { value: "ja-JP", label: "Japanese" },
    { value: "kn-IN", label: "Kannada" },
    { value: "ko-KR", label: "Korean" },
    { value: "lv-LV", label: "Latvian" },
    { value: "lt-LT", label: "Lithuanian" },
    { value: "ms-MY", label: "Malay" },
    { value: "ml-IN", label: "Malayalam" },
    { value: "cmn-CN", label: "Mandarin Chinese" },
    { value: "mr-IN", label: "Marathi" },
    { value: "nb-NO", label: "Norwegian" },
    { value: "pl-PL", label: "Polish" },
    { value: "pt-PT", label: "Portuguese" },
    { value: "pa-IN", label: "Punjabi" },
    { value: "ro-RO", label: "Romanian" },
    { value: "ru-RU", label: "Russian" },
    { value: "sr-RS", label: "Serbian" },
    { value: "sk-SK", label: "Slovak" },
    { value: "es-ES", label: "Spanish" },
    { value: "sv-SE", label: "Swedish" },
    { value: "ta-IN", label: "Tamil" },
    { value: "te-IN", label: "Telugu" },
    { value: "th-TH", label: "Thai" },
    { value: "tr-TR", label: "Turkish" },
    { value: "uk-UA", label: "Ukrainian" },
    { value: "vi-VN", label: "Vietnamese" },
  ];

  const VoiceSelection = [
    // { value: 'af-ZA-Standard-A', label: 'Female 1', gender: 'female', language: 'af-ZA' },

    // { value: 'ar-XA-Standard-A', label: 'Female 1', gender: 'female', language: 'ar-XA' },
    // { value: 'ar-XA-Standard-B', label: 'Male 1', gender: 'male', language: 'ar-XA' },
    // { value: 'ar-XA-Standard-C', label: 'Male 2', gender: 'male', language: 'ar-XA' },
    // { value: 'ar-XA-Standard-D', label: 'Female 2', gender: 'female', language: 'ar-XA' },
    // { value: 'ar-XA-Wavenet-A', label: 'Female 3', gender: 'female', language: 'ar-XA' },
    // { value: 'ar-XA-Wavenet-B', label: 'Male 3', gender: 'male', language: 'ar-XA' },
    // { value: 'ar-XA-Wavenet-C', label: 'Male 4', gender: 'male', language: 'ar-XA' },
    // { value: 'ar-XA-Wavenet-D', label: 'Female 4', gender: 'female', language: 'ar-XA' },

    // { value: 'bn-IN-Standard-A', label: 'Female 1', gender: 'female', language: 'bn-IN' },
    // { value: 'bn-IN-Standard-B', label: 'Male 1', gender: 'male', language: 'bn-IN' },
    // { value: 'bn-IN-Standard-C', label: 'Female 2', gender: 'female', language: 'bn-IN' },
    // { value: 'bn-IN-Standard-D', label: 'Male 2', gender: 'male', language: 'bn-IN' },
    // { value: 'bn-IN-Wavenet-A', label: 'Female 3', gender: 'female', language: 'bn-IN' },
    // { value: 'bn-IN-Wavenet-B', label: 'Male 3', gender: 'male', language: 'bn-IN' },
    // { value: 'bn-IN-Wavenet-C', label: 'Female 4', gender: 'female', language: 'bn-IN' },
    // { value: 'bn-IN-Wavenet-D', label: 'Male 4', gender: 'male', language: 'bn-IN' },

    // { value: 'bn-IN-Wavenet-C', label: 'Female 4', gender: 'female', language: 'cs-CZ' },
    // { value: 'bn-IN-Wavenet-D', label: 'Male 4', gender: 'male', language: 'cs-CZ' },

    {
      value: "af-ZA-Standard-A",
      label: "Anele",
      gender: "female",
      language: "af-ZA",
    },
    {
      value: "ar-XA-Standard-A",
      label: "Arwa",
      gender: "female",
      language: "ar-XA",
    },
    {
      value: "ar-XA-Standard-B",
      label: "Amir",
      gender: "male",
      language: "ar-XA",
    },
    {
      value: "ar-XA-Standard-C",
      label: "Omar",
      gender: "male",
      language: "ar-XA",
    },
    {
      value: "ar-XA-Standard-D",
      label: "Amal",
      gender: "female",
      language: "ar-XA",
    },
    {
      value: "ar-XA-Wavenet-A",
      label: "Haifa",
      gender: "female",
      language: "ar-XA",
    },
    {
      value: "ar-XA-Wavenet-B",
      label: "Arif",
      gender: "male",
      language: "ar-XA",
    },
    {
      value: "ar-XA-Wavenet-C",
      label: "Nadim",
      gender: "male",
      language: "ar-XA",
    },
    {
      value: "ar-XA-Wavenet-D",
      label: "Muhsina",
      gender: "female",
      language: "ar-XA",
    },
    {
      value: "eu-ES-Standard-A",
      label: "Valentina",
      gender: "female",
      language: "eu-ES",
    },
    {
      value: "bn-IN-Standard-A",
      label: "Jhanvi",
      gender: "female",
      language: "bn-IN",
    },
    {
      value: "bn-IN-Standard-B",
      label: "Aakash",
      gender: "male",
      language: "bn-IN",
    },
    {
      value: "bn-IN-Standard-C",
      label: "Aaditri",
      gender: "female",
      language: "bn-IN",
    },
    {
      value: "bn-IN-Standard-D",
      label: "Abhijit",
      gender: "male",
      language: "bn-IN",
    },
    {
      value: "bn-IN-Wavenet-A",
      label: "Aakashi",
      gender: "female",
      language: "bn-IN",
    },
    {
      value: "bn-IN-Wavenet-B",
      label: "Abhay",
      gender: "male",
      language: "bn-IN",
    },
    {
      value: "bn-IN-Wavenet-C",
      label: "Aalia",
      gender: "female",
      language: "bn-IN",
    },
    {
      value: "bn-IN-Wavenet-D",
      label: "Aarish",
      gender: "male",
      language: "bn-IN",
    },
    {
      value: "bg-BG-Standard-A",
      label: "Shivali",
      gender: "female",
      language: "bg-BG",
    },
    {
      value: "ca-ES-Standard-A",
      label: "Emilia",
      gender: "female",
      language: "ca-ES",
    },
    {
      value: "yue-HK-Standard-A",
      label: "Hui",
      gender: "female",
      language: "yue-HK",
    },
    {
      value: "yue-HK-Standard-B",
      label: "Wong",
      gender: "male",
      language: "yue-HK",
    },
    {
      value: "yue-HK-Standard-C",
      label: "Chloe",
      gender: "female",
      language: "yue-HK",
    },
    {
      value: "yue-HK-Standard-D",
      label: "Chan",
      gender: "male",
      language: "yue-HK",
    },
    {
      value: "cs-CZ-Standard-A",
      label: "Blanka",
      gender: "female",
      language: "cs-CZ",
    },
    {
      value: "cs-CZ-Wavenet-A",
      label: "Marika",
      gender: "female",
      language: "cs-CZ",
    },
    {
      value: "da-DK-Neural2-D",
      label: "Asta",
      gender: "female",
      language: "da-DK",
    },
    {
      value: "da-DK-Standard-A",
      label: "Emma",
      gender: "female",
      language: "da-DK",
    },
    {
      value: "da-DK-Standard-C",
      label: "Alexander",
      gender: "male",
      language: "da-DK",
    },
    {
      value: "da-DK-Standard-D",
      label: "Alberte",
      gender: "female",
      language: "da-DK",
    },
    {
      value: "da-DK-Standard-E",
      label: "Freja",
      gender: "female",
      language: "da-DK",
    },
    {
      value: "da-DK-Wavenet-A",
      label: "Charlotta",
      gender: "female",
      language: "da-DK",
    },
    {
      value: "da-DK-Wavenet-C",
      label: "Alfred",
      gender: "male",
      language: "da-DK",
    },
    {
      value: "da-DK-Wavenet-D",
      label: "Dorrit",
      gender: "female",
      language: "da-DK",
    },
    {
      value: "da-DK-Wavenet-E",
      label: "Elsebeth",
      gender: "female",
      language: "da-DK",
    },
    {
      value: "nl-BE-Standard-A",
      label: "Emma",
      gender: "female",
      language: "nl-BE",
    },
    {
      value: "nl-BE-Standard-B",
      label: "Abel",
      gender: "male",
      language: "nl-BE",
    },
    {
      value: "nl-BE-Wavenet-A",
      label: "Sophie",
      gender: "female",
      language: "nl-BE",
    },
    {
      value: "nl-BE-Wavenet-B",
      label: "Julia",
      gender: "male",
      language: "nl-BE",
    },
    {
      value: "nl-NL-Standard-A",
      label: "Sara",
      gender: "female",
      language: "nl-NL",
    },
    {
      value: "nl-NL-Standard-B",
      label: "Ambroos",
      gender: "male",
      language: "nl-NL",
    },
    {
      value: "nl-NL-Standard-C",
      label: "Boudewijn",
      gender: "male",
      language: "nl-NL",
    },
    {
      value: "nl-NL-Standard-D",
      label: "Anna",
      gender: "female",
      language: "nl-NL",
    },
    {
      value: "nl-NL-Standard-E",
      label: "Evi",
      gender: "female",
      language: "nl-NL",
    },
    {
      value: "nl-NL-Wavenet-A",
      label: "Zoë",
      gender: "female",
      language: "nl-NL",
    },
    {
      value: "nl-NL-Wavenet-B",
      label: "Christoffel",
      gender: "male",
      language: "nl-NL",
    },
    {
      value: "nl-NL-Wavenet-C",
      label: "Diederik",
      gender: "male",
      language: "nl-NL",
    },
    {
      value: "nl-NL-Wavenet-D",
      label: "Olivia",
      gender: "female",
      language: "nl-NL",
    },
    {
      value: "nl-NL-Wavenet-E",
      label: "Lotte",
      gender: "female",
      language: "nl-NL",
    },
    {
      value: "en-AU-Neural2-A",
      label: "Charlotte",
      gender: "female",
      language: "en-AU",
    },
    {
      value: "en-AU-Neural2-B",
      label: "Oliver",
      gender: "male",
      language: "en-AU",
    },
    {
      value: "en-AU-Neural2-C",
      label: "Amelia",
      gender: "female",
      language: "en-AU",
    },
    {
      value: "en-AU-Neural2-D",
      label: "Noah",
      gender: "male",
      language: "en-AU",
    },
    {
      value: "en-AU-News-E",
      label: "Olivia",
      gender: "female",
      language: "en-AU",
    },
    {
      value: "en-AU-News-F",
      label: "Lily",
      gender: "female",
      language: "en-AU",
    },
    {
      value: "en-AU-News-G",
      label: "William",
      gender: "male",
      language: "en-AU",
    },
    {
      value: "en-AU-Polyglot-1",
      label: "Jack",
      gender: "male",
      language: "en-AU",
    },
    {
      value: "en-AU-Standard-A",
      label: "Zoe",
      gender: "female",
      language: "en-AU",
    },
    {
      value: "en-AU-Standard-B",
      label: "Henry",
      gender: "male",
      language: "en-AU",
    },
    {
      value: "en-AU-Standard-C",
      label: "Sofia",
      gender: "female",
      language: "en-AU",
    },
    {
      value: "en-AU-Standard-D",
      label: "James",
      gender: "male",
      language: "en-AU",
    },
    {
      value: "en-AU-Wavenet-A",
      label: "Ava",
      gender: "female",
      language: "en-AU",
    },
    {
      value: "en-AU-Wavenet-B",
      label: "Lucas",
      gender: "male",
      language: "en-AU",
    },
    {
      value: "en-AU-Wavenet-C",
      label: "Ella",
      gender: "female",
      language: "en-AU",
    },
    {
      value: "en-AU-Wavenet-D",
      label: "Thomas",
      gender: "male",
      language: "en-AU",
    },
    {
      value: "en-IN-Neural2-A",
      label: "Aadhya",
      gender: "female",
      language: "en-IN",
    },
    {
      value: "en-IN-Neural2-B",
      label: "Aarav",
      gender: "male",
      language: "en-IN",
    },
    {
      value: "en-IN-Neural2-C",
      label: "Arjun",
      gender: "male",
      language: "en-IN",
    },
    {
      value: "en-IN-Neural2-D",
      label: "Aaradhya",
      gender: "female",
      language: "en-IN",
    },
    {
      value: "en-IN-Standard-A",
      label: "Anaya",
      gender: "female",
      language: "en-IN",
    },
    {
      value: "en-IN-Standard-B",
      label: "Aditya",
      gender: "male",
      language: "en-IN",
    },
    {
      value: "en-IN-Standard-C",
      label: "Krishna",
      gender: "male",
      language: "en-IN",
    },
    {
      value: "en-IN-Standard-D",
      label: "Diya",
      gender: "female",
      language: "en-IN",
    },
    {
      value: "en-IN-Wavenet-A",
      label: "Isha",
      gender: "female",
      language: "en-IN",
    },
    {
      value: "en-IN-Wavenet-B",
      label: "Dhruv",
      gender: "male",
      language: "en-IN",
    },
    {
      value: "en-IN-Wavenet-C",
      label: "Kabir",
      gender: "male",
      language: "en-IN",
    },
    {
      value: "en-IN-Wavenet-D",
      label: "Jiya",
      gender: "female",
      language: "en-IN",
    },
    {
      value: "en-GB-Neural2-A",
      label: "Olivia",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-Neural2-B",
      label: "George",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-Neural2-C",
      label: "Amelia",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-Neural2-D",
      label: "Harry",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-Neural2-F",
      label: "Ivy",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-News-G",
      label: "Lily",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-News-H",
      label: "Grace",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-News-I",
      label: "Freya",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-News-J",
      label: "Jack",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-News-K",
      label: "Arthur",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-News-L",
      label: "Charlie",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-News-M",
      label: "Jacob",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-Standard-A",
      label: "Emily",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-Standard-B",
      label: "Henry",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-Standard-C",
      label: "Florence",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-Standard-D",
      label: "Thomas",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-Standard-F",
      label: "Poppy",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-Studio-B",
      label: "Freddie",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-Studio-C",
      label: "Evie",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-Wavenet-A",
      label: "Ella",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-Wavenet-B",
      label: "William",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-Wavenet-C",
      label: "Sophia",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-GB-Wavenet-D",
      label: "Omar",
      gender: "male",
      language: "en-GB",
    },
    {
      value: "en-GB-Wavenet-F",
      label: "Ava",
      gender: "female",
      language: "en-GB",
    },
    {
      value: "en-US-Casual-K",
      label: "Liam",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Journey-D",
      label: "Ethan",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Journey-F",
      label: "Emma",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Neural2-A",
      label: "James",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Neural2-C",
      label: "Charlotte",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Neural2-D",
      label: "Benjamin",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Neural2-E",
      label: "Ava",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Neural2-F",
      label: "Sophia",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Neural2-G",
      label: "Isabella",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Neural2-H",
      label: "Mia",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Neural2-I",
      label: "Elijah",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Neural2-J",
      label: "Lucas",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-News-K",
      label: "Amelia",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-News-L",
      label: "Harper",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-News-N",
      label: "Alexander",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Polyglot-1",
      label: "Sebastian",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Standard-A",
      label: "Daniel",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Standard-B",
      label: "Matthew",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Standard-C",
      label: "Evelyn",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Standard-D",
      label: "Aiden",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Standard-E",
      label: "Abigail",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Standard-F",
      label: "Ella",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Standard-G",
      label: "Scarlett",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Standard-H",
      label: "Luna",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Standard-I",
      label: "Jackson",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Standard-J",
      label: "Carter",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Studio-O",
      label: "Aria",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Studio-Q",
      label: "Samuel",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-A",
      label: "Logan",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-B",
      label: "Henry",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-C",
      label: "Layla",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-D",
      label: "Wyatt",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-E",
      label: "Chloe",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-F",
      label: "Mila",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-G",
      label: "Nora",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-H",
      label: "Hazel",
      gender: "female",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-I",
      label: "Jayden",
      gender: "male",
      language: "en-US",
    },
    {
      value: "en-US-Wavenet-J",
      label: "Gabriel",
      gender: "male",
      language: "en-US",
    },
    {
      value: "fil-PH-Standard-A",
      label: "Maria",
      gender: "female",
      language: "fil-PH",
    },
    {
      value: "fil-PH-Standard-B",
      label: "Gabrielle",
      gender: "female",
      language: "fil-PH",
    },
    {
      value: "fil-PH-Standard-C",
      label: "Jose ",
      gender: "male",
      language: "fil-PH",
    },
    {
      value: "fil-PH-Standard-D",
      label: "Juan ",
      gender: "male",
      language: "fil-PH",
    },
    {
      value: "fil-PH-Wavenet-A",
      label: "Samantha",
      gender: "female",
      language: "fil-PH",
    },
    {
      value: "fil-PH-Wavenet-B",
      label: "Sophia",
      gender: "female",
      language: "fil-PH",
    },
    {
      value: "fil-PH-Wavenet-C",
      label: "James ",
      gender: "male",
      language: "fil-PH",
    },
    {
      value: "fil-PH-Wavenet-D",
      label: "Gabriel ",
      gender: "male",
      language: "fil-PH",
    },
    {
      value: "fil-ph-Neural2-A",
      label: "Isabella",
      gender: "female",
      language: "fil-PH",
    },
    {
      value: "fil-ph-Neural2-D",
      label: "Daniel ",
      gender: "male",
      language: "fil-PH",
    },
    {
      value: "fi-FI-Standard-A",
      label: "Nicole",
      gender: "female",
      language: "fi-FI",
    },
    {
      value: "fi-FI-Wavenet-A",
      label: "Katherine",
      gender: "female",
      language: "fi-FI",
    },
    {
      value: "fr-CA-Neural2-A",
      label: "Elaine",
      gender: "female",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Neural2-B",
      label: "Joshua ",
      gender: "male",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Neural2-C",
      label: "Louise ",
      gender: "female",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Neural2-D",
      label: "William ",
      gender: "male",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Standard-A",
      label: "Chloé ",
      gender: "female",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Standard-B",
      label: "Thomas ",
      gender: "male",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Standard-C",
      label: "Alice ",
      gender: "female",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Standard-D",
      label: "Liam ",
      gender: "male",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Wavenet-A",
      label: "Léa ",
      gender: "female",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Wavenet-B",
      label: "Gabriel ",
      gender: "male",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Wavenet-C",
      label: "Charlotte",
      gender: "female",
      language: "fr-CA",
    },
    {
      value: "fr-CA-Wavenet-D",
      label: "Nathan ",
      gender: "male",
      language: "fr-CA",
    },
    {
      value: "fr-FR-Neural2-A",
      label: "Camille",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Neural2-B",
      label: "Jacob ",
      gender: "male",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Neural2-C",
      label: "Lola",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Neural2-D",
      label: "Louis",
      gender: "male",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Neural2-E",
      label: "Inès ",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Polyglot-1",
      label: "Arthur ",
      gender: "male",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Standard-A",
      label: "Juliette ",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Standard-B",
      label: "Jules ",
      gender: "male",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Standard-C",
      label: "Anna ",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Standard-D",
      label: "Hugo ",
      gender: "male",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Standard-E",
      label: "Margot ",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Studio-A",
      label: "Éléonore ",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Studio-D",
      label: "Raphael ",
      gender: "male",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Wavenet-A",
      label: "Zoé ",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Wavenet-B",
      label: "Nathan ",
      gender: "male",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Wavenet-C",
      label: "Anna ",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Wavenet-D",
      label: "Paul ",
      gender: "male",
      language: "fr-FR",
    },
    {
      value: "fr-FR-Wavenet-E",
      label: "Alice ",
      gender: "female",
      language: "fr-FR",
    },
    {
      value: "gl-ES-Standard-A",
      label: "Emma",
      gender: "female",
      language: "gl-ES",
    },
    {
      value: "de-DE-Neural2-A",
      label: "Mia",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "de-DE-Neural2-B",
      label: "Maximilian",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Neural2-C",
      label: "Hanna",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "de-DE-Neural2-D",
      label: "Alexander",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Neural2-F",
      label: "Emilia",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "de-DE-Polyglot-1",
      label: "Paul",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Standard-A",
      label: "Lina",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "de-DE-Standard-B",
      label: "Mia",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Standard-C",
      label: "Emma",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "de-DE-Standard-D",
      label: "Elias",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Standard-E",
      label: "Felix",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Standard-F",
      label: "Sophia",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "de-DE-Studio-B",
      label: "Leon",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Studio-C",
      label: "Ella",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "de-DE-Wavenet-A",
      label: "Marie",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "de-DE-Wavenet-B",
      label: "Lukas",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Wavenet-C",
      label: "Clara",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "de-DE-Wavenet-D",
      label: "Maximilian",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Wavenet-E",
      label: "Finn",
      gender: "male",
      language: "de-DE",
    },
    {
      value: "de-DE-Wavenet-F",
      label: "Leni",
      gender: "female",
      language: "de-DE",
    },
    {
      value: "el-GR-Standard-A",
      label: "Ioanna",
      gender: "female",
      language: "el-GR",
    },
    {
      value: "el-GR-Wavenet-A",
      label: "Despoina",
      gender: "female",
      language: "el-GR",
    },
    {
      value: "gu-IN-Standard-A",
      label: "Aarvi",
      gender: "female",
      language: "gu-IN",
    },
    {
      value: "gu-IN-Standard-B",
      label: "Krish",
      gender: "male",
      language: "gu-IN",
    },
    {
      value: "gu-IN-Wavenet-A",
      label: "Aarohi",
      gender: "female",
      language: "gu-IN",
    },
    {
      value: "gu-IN-Wavenet-B",
      label: "Yash",
      gender: "male",
      language: "gu-IN",
    },
    {
      value: "he-IL-Standard-A",
      label: "Shira",
      gender: "female",
      language: "he-IL",
    },
    {
      value: "he-IL-Standard-B",
      label: "Noam",
      gender: "male",
      language: "he-IL",
    },
    {
      value: "he-IL-Standard-C",
      label: "Adi",
      gender: "female",
      language: "he-IL",
    },
    {
      value: "he-IL-Standard-D",
      label: "David",
      gender: "male",
      language: "he-IL",
    },
    {
      value: "he-IL-Wavenet-A",
      label: "Noya",
      gender: "female",
      language: "he-IL",
    },
    {
      value: "he-IL-Wavenet-B",
      label: "Daniel",
      gender: "male",
      language: "he-IL",
    },
    {
      value: "he-IL-Wavenet-C",
      label: "Shir",
      gender: "female",
      language: "he-IL",
    },
    {
      value: "he-IL-Wavenet-D",
      label: "Ariel",
      gender: "male",
      language: "he-IL",
    },
    {
      value: "hi-IN-Neural2-A",
      label: "Anaya",
      gender: "female",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Neural2-B",
      label: "Arjun",
      gender: "male",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Neural2-C",
      label: "Advik",
      gender: "male",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Neural2-D",
      label: "Aanya",
      gender: "female",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Standard-A",
      label: "Aarohi",
      gender: "female",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Standard-B",
      label: "Avinash",
      gender: "male",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Standard-C",
      label: "Amit",
      gender: "male",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Standard-D",
      label: "Aditi",
      gender: "female",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Wavenet-A",
      label: "Aahana",
      gender: "female",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Wavenet-B",
      label: "Arya",
      gender: "male",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Wavenet-C",
      label: "Aadi",
      gender: "male",
      language: "hi-IN",
    },
    {
      value: "hi-IN-Wavenet-D",
      label: "Amaya",
      gender: "female",
      language: "hi-IN",
    },
    {
      value: "hu-HU-Standard-A",
      label: "Anna",
      gender: "female",
      language: "hu-HU",
    },
    {
      value: "hu-HU-Wavenet-A",
      label: "Andrea",
      gender: "female",
      language: "hu-HU",
    },
    {
      value: "is-IS-Standard-A",
      label: "Anna",
      gender: "female",
      language: "is-IS",
    },
    {
      value: "id-ID-Standard-A",
      label: "Dewi",
      gender: "female",
      language: "id-ID",
    },
    {
      value: "id-ID-Standard-B",
      label: "Dharma",
      gender: "male",
      language: "id-ID",
    },
    {
      value: "id-ID-Standard-C",
      label: "Darma",
      gender: "male",
      language: "id-ID",
    },
    {
      value: "id-ID-Standard-D",
      label: "Dhriti",
      gender: "female",
      language: "id-ID",
    },
    {
      value: "id-ID-Wavenet-A",
      label: "Della",
      gender: "female",
      language: "id-ID",
    },
    {
      value: "id-ID-Wavenet-B",
      label: "Daffa",
      gender: "male",
      language: "id-ID",
    },
    {
      value: "id-ID-Wavenet-C",
      label: "Desta",
      gender: "male",
      language: "id-ID",
    },
    {
      value: "id-ID-Wavenet-D",
      label: "Dinara",
      gender: "female",
      language: "id-ID",
    },
    {
      value: "it-IT-Neural2-A",
      label: "Alessia",
      gender: "female",
      language: "it-IT",
    },
    {
      value: "it-IT-Neural2-C",
      label: "Giovanni",
      gender: "male",
      language: "it-IT",
    },
    {
      value: "it-IT-Standard-A",
      label: "Sofia",
      gender: "female",
      language: "it-IT",
    },
    {
      value: "it-IT-Standard-B",
      label: "Giulia",
      gender: "female",
      language: "it-IT",
    },
    {
      value: "it-IT-Standard-C",
      label: "Luca",
      gender: "male",
      language: "it-IT",
    },
    {
      value: "it-IT-Standard-D",
      label: "Matteo",
      gender: "male",
      language: "it-IT",
    },
    {
      value: "it-IT-Wavenet-A",
      label: "Aurora",
      gender: "female",
      language: "it-IT",
    },
    {
      value: "it-IT-Wavenet-B",
      label: "Martina",
      gender: "female",
      language: "it-IT",
    },
    {
      value: "it-IT-Wavenet-C",
      label: "Marco",
      gender: "male",
      language: "it-IT",
    },
    {
      value: "it-IT-Wavenet-D",
      label: "Riccardo",
      gender: "male",
      language: "it-IT",
    },
    {
      value: "ja-JP-Neural2-B",
      label: "Sakura",
      gender: "female",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Neural2-C",
      label: "Ryota",
      gender: "male",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Neural2-D",
      label: "Takumi",
      gender: "male",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Standard-A",
      label: "Yui",
      gender: "female",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Standard-B",
      label: "Mio",
      gender: "female",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Standard-C",
      label: "Haruto",
      gender: "male",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Standard-D",
      label: "Sota",
      gender: "male",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Wavenet-A",
      label: "Hana",
      gender: "female",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Wavenet-B",
      label: "Airi",
      gender: "female",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Wavenet-C",
      label: "Riku",
      gender: "male",
      language: "ja-JP",
    },
    {
      value: "ja-JP-Wavenet-D",
      label: "Yuto",
      gender: "male",
      language: "ja-JP",
    },
    {
      value: "kn-IN-Standard-A",
      label: "Aishwarya",
      gender: "female",
      language: "kn-IN",
    },
    {
      value: "kn-IN-Standard-B",
      label: "Arjun",
      gender: "male",
      language: "kn-IN",
    },
    {
      value: "kn-IN-Standard-C",
      label: "Divya",
      gender: "female",
      language: "kn-IN",
    },
    {
      value: "kn-IN-Standard-D",
      label: "Girish",
      gender: "male",
      language: "kn-IN",
    },
    {
      value: "kn-IN-Wavenet-A",
      label: "Kavya",
      gender: "female",
      language: "kn-IN",
    },
    {
      value: "kn-IN-Wavenet-B",
      label: "Naveen",
      gender: "male",
      language: "kn-IN",
    },
    {
      value: "kn-IN-Wavenet-C",
      label: "Pooja",
      gender: "female",
      language: "kn-IN",
    },
    {
      value: "kn-IN-Wavenet-D",
      label: "Rakesh",
      gender: "male",
      language: "kn-IN",
    },
    {
      value: "ko-KR-Neural2-A",
      label: "Yuna",
      gender: "female",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Neural2-B",
      label: "Jiwoo",
      gender: "female",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Neural2-C",
      label: "Minho",
      gender: "male",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Standard-A",
      label: "Eunji",
      gender: "female",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Standard-B",
      label: "Hyunwoo",
      gender: "male",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Standard-C",
      label: "Jiyeon",
      gender: "female",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Standard-D",
      label: "Jinwoo",
      gender: "male",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Wavenet-A",
      label: "Seo-yun",
      gender: "female",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Wavenet-B",
      label: "Hyunjin",
      gender: "female",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Wavenet-C",
      label: "Joon",
      gender: "male",
      language: "ko-KR",
    },
    {
      value: "ko-KR-Wavenet-D",
      label: "Yoon",
      gender: "male",
      language: "ko-KR",
    },
    {
      value: "lv-LV-Standard-A",
      label: "Juris",
      gender: "male",
      language: "lv-LV",
    },
    {
      value: "lt-LT-Standard-A",
      label: "Tomas",
      gender: "male",
      language: "lt-LT",
    },
    {
      value: "ms-MY-Standard-A",
      label: "Siti",
      gender: "female",
      language: "ms-MY",
    },
    {
      value: "ms-MY-Standard-B",
      label: "Adam",
      gender: "male",
      language: "ms-MY",
    },
    {
      value: "ms-MY-Standard-C",
      label: "Nor",
      gender: "female",
      language: "ms-MY",
    },
    {
      value: "ms-MY-Standard-D",
      label: "Ismail",
      gender: "male",
      language: "ms-MY",
    },
    {
      value: "ms-MY-Wavenet-A",
      label: "Aisyah",
      gender: "female",
      language: "ms-MY",
    },
    {
      value: "ms-MY-Wavenet-B",
      label: "Ahmad",
      gender: "male",
      language: "ms-MY",
    },
    {
      value: "ms-MY-Wavenet-C",
      label: "Farah",
      gender: "female",
      language: "ms-MY",
    },
    {
      value: "ms-MY-Wavenet-D",
      label: "Azman",
      gender: "male",
      language: "ms-MY",
    },
    {
      value: "ml-IN-Standard-A",
      label: "Asha",
      gender: "female",
      language: "ml-IN",
    },
    {
      value: "ml-IN-Standard-B",
      label: "Anoop",
      gender: "male",
      language: "ml-IN",
    },
    {
      value: "ml-IN-Wavenet-A",
      label: "Deepa",
      gender: "female",
      language: "ml-IN",
    },
    {
      value: "ml-IN-Wavenet-B",
      label: "Binu",
      gender: "male",
      language: "ml-IN",
    },
    {
      value: "ml-IN-Wavenet-C",
      label: "Geetha",
      gender: "female",
      language: "ml-IN",
    },
    {
      value: "ml-IN-Wavenet-D",
      label: "Dinesh",
      gender: "male",
      language: "ml-IN",
    },
    {
      value: "cmn-CN-Standard-A",
      label: "Yan",
      gender: "female",
      language: "cmn-CN",
    },
    {
      value: "cmn-CN-Standard-B",
      label: "Jun",
      gender: "male",
      language: "cmn-CN",
    },
    {
      value: "cmn-CN-Standard-C",
      label: "Wei",
      gender: "male",
      language: "cmn-CN",
    },
    {
      value: "cmn-CN-Standard-D",
      label: "Xiao",
      gender: "female",
      language: "cmn-CN",
    },
    {
      value: "cmn-CN-Wavenet-A",
      label: "Mei",
      gender: "female",
      language: "cmn-CN",
    },
    {
      value: "cmn-CN-Wavenet-B",
      label: "Li",
      gender: "male",
      language: "cmn-CN",
    },
    {
      value: "cmn-CN-Wavenet-C",
      label: "Feng",
      gender: "male",
      language: "cmn-CN",
    },
    {
      value: "cmn-CN-Wavenet-D",
      label: "Hua",
      gender: "female",
      language: "cmn-CN",
    },
    {
      value: "cmn-TW-Standard-A",
      label: "Mei",
      gender: "female",
      language: "cmn-TW",
    },
    {
      value: "cmn-TW-Standard-B",
      label: "Jun",
      gender: "male",
      language: "cmn-TW",
    },
    {
      value: "cmn-TW-Standard-C",
      label: "Wei",
      gender: "male",
      language: "cmn-TW",
    },
    {
      value: "cmn-TW-Wavenet-A",
      label: "Xiao",
      gender: "female",
      language: "cmn-TW",
    },
    {
      value: "cmn-TW-Wavenet-B",
      label: "Feng",
      gender: "male",
      language: "cmn-TW",
    },
    {
      value: "cmn-TW-Wavenet-C",
      label: "Hua",
      gender: "male",
      language: "cmn-TW",
    },
    {
      value: "mr-IN-Standard-A",
      label: "Jaya",
      gender: "female",
      language: "mr-IN",
    },
    {
      value: "mr-IN-Standard-B",
      label: "Rohan",
      gender: "male",
      language: "mr-IN",
    },
    {
      value: "mr-IN-Standard-C",
      label: "Priya",
      gender: "female",
      language: "mr-IN",
    },
    {
      value: "mr-IN-Wavenet-A",
      label: "Smita",
      gender: "female",
      language: "mr-IN",
    },
    {
      value: "mr-IN-Wavenet-B",
      label: "Nikhil",
      gender: "male",
      language: "mr-IN",
    },
    {
      value: "mr-IN-Wavenet-C",
      label: "Kavita",
      gender: "female",
      language: "mr-IN",
    },
    {
      value: "nb-NO-Standard-A",
      label: "Ingrid",
      gender: "female",
      language: "nb-NO",
    },
    {
      value: "nb-NO-Standard-B",
      label: "Anders",
      gender: "male",
      language: "nb-NO",
    },
    {
      value: "nb-NO-Standard-C",
      label: "Sofie",
      gender: "female",
      language: "nb-NO",
    },
    {
      value: "nb-NO-Standard-D",
      label: "Erik",
      gender: "male",
      language: "nb-NO",
    },
    {
      value: "nb-NO-Standard-E",
      label: "Ingrid",
      gender: "female",
      language: "nb-NO",
    },
    {
      value: "nb-NO-Wavenet-A",
      label: "Marte",
      gender: "female",
      language: "nb-NO",
    },
    {
      value: "nb-NO-Wavenet-B",
      label: "Ole",
      gender: "male",
      language: "nb-NO",
    },
    {
      value: "nb-NO-Wavenet-C",
      label: "Nora",
      gender: "female",
      language: "nb-NO",
    },
    {
      value: "nb-NO-Wavenet-D",
      label: "Per",
      gender: "male",
      language: "nb-NO",
    },
    {
      value: "nb-NO-Wavenet-E",
      label: "Selma",
      gender: "female",
      language: "nb-NO",
    },
    {
      value: "pl-PL-Standard-A",
      label: "Anna",
      gender: "female",
      language: "pl-PL",
    },
    {
      value: "pl-PL-Standard-B",
      label: "Kacper",
      gender: "male",
      language: "pl-PL",
    },
    {
      value: "pl-PL-Standard-C",
      label: "Filip",
      gender: "male",
      language: "pl-PL",
    },
    {
      value: "pl-PL-Standard-D",
      label: "Zofia",
      gender: "female",
      language: "pl-PL",
    },
    {
      value: "pl-PL-Standard-E",
      label: "Maja",
      gender: "female",
      language: "pl-PL",
    },
    {
      value: "pl-PL-Wavenet-A",
      label: "Julia",
      gender: "female",
      language: "pl-PL",
    },
    {
      value: "pl-PL-Wavenet-B",
      label: "Adam",
      gender: "male",
      language: "pl-PL",
    },
    {
      value: "pl-PL-Wavenet-C",
      label: "Piotr",
      gender: "male",
      language: "pl-PL",
    },
    {
      value: "pl-PL-Wavenet-D",
      label: "Magdalena",
      gender: "female",
      language: "pl-PL",
    },
    {
      value: "pl-PL-Wavenet-E",
      label: "Alicja",
      gender: "female",
      language: "pl-PL",
    },
    {
      value: "pt-BR-Neural2-A",
      label: "Carolina",
      gender: "female",
      language: "pt-BR",
    },
    {
      value: "pt-BR-Neural2-B",
      label: "Lucas",
      gender: "male",
      language: "pt-BR",
    },
    {
      value: "pt-BR-Neural2-C",
      label: "Beatriz",
      gender: "female",
      language: "pt-BR",
    },
    {
      value: "pt-BR-Standard-A",
      label: "Isabela",
      gender: "female",
      language: "pt-BR",
    },
    {
      value: "pt-BR-Standard-B",
      label: "Pedro",
      gender: "male",
      language: "pt-BR",
    },
    {
      value: "pt-BR-Standard-C",
      label: "Camila",
      gender: "female",
      language: "pt-BR",
    },
    {
      value: "pt-BR-Wavenet-A",
      label: "Juliana",
      gender: "female",
      language: "pt-BR",
    },
    {
      value: "pt-BR-Wavenet-B",
      label: "Miguel",
      gender: "male",
      language: "pt-BR",
    },
    {
      value: "pt-BR-Wavenet-C",
      label: "Fernanda",
      gender: "female",
      language: "pt-BR",
    },
    {
      value: "pt-PT-Standard-A",
      label: "Mariana",
      gender: "female",
      language: "pt-PT",
    },
    {
      value: "pt-PT-Standard-B",
      label: "Tiago",
      gender: "male",
      language: "pt-PT",
    },
    {
      value: "pt-PT-Standard-C",
      label: "Rui",
      gender: "male",
      language: "pt-PT",
    },
    {
      value: "pt-PT-Standard-D",
      label: "Maria",
      gender: "female",
      language: "pt-PT",
    },
    {
      value: "pt-PT-Wavenet-A",
      label: "Beatriz",
      gender: "female",
      language: "pt-PT",
    },
    {
      value: "pt-PT-Wavenet-B",
      label: "Pedro",
      gender: "male",
      language: "pt-PT",
    },
    {
      value: "pt-PT-Wavenet-C",
      label: "João",
      gender: "male",
      language: "pt-PT",
    },
    {
      value: "pt-PT-Wavenet-D",
      label: "Ana",
      gender: "female",
      language: "pt-PT",
    },
    {
      value: "pa-IN-Standard-A",
      label: "Jasleen",
      gender: "female",
      language: "pa-IN",
    },
    {
      value: "pa-IN-Standard-B",
      label: "Arun",
      gender: "male",
      language: "pa-IN",
    },
    {
      value: "pa-IN-Standard-C",
      label: "Manpreet",
      gender: "female",
      language: "pa-IN",
    },
    {
      value: "pa-IN-Standard-D",
      label: "Deepak",
      gender: "male",
      language: "pa-IN",
    },
    {
      value: "pa-IN-Wavenet-A",
      label: "Gurleen",
      gender: "female",
      language: "pa-IN",
    },
    {
      value: "pa-IN-Wavenet-B",
      label: "Amit",
      gender: "male",
      language: "pa-IN",
    },
    {
      value: "pa-IN-Wavenet-C",
      label: "Ravneet",
      gender: "female",
      language: "pa-IN",
    },
    {
      value: "pa-IN-Wavenet-D",
      label: "Gurpreet",
      gender: "male",
      language: "pa-IN",
    },
    {
      value: "ro-RO-Standard-A",
      label: "Ioana",
      gender: "female",
      language: "ro-RO",
    },
    {
      value: "ro-RO-Wavenet-A",
      label: "Andreea",
      gender: "female",
      language: "ro-RO",
    },
    {
      value: "ru-RU-Standard-A",
      label: "Anastasia",
      gender: "female",
      language: "ru-RU",
    },
    {
      value: "ru-RU-Standard-B",
      label: "Dmitri",
      gender: "male",
      language: "ru-RU",
    },
    {
      value: "ru-RU-Standard-C",
      label: "Svetlana",
      gender: "female",
      language: "ru-RU",
    },
    {
      value: "ru-RU-Standard-D",
      label: "Ivan",
      gender: "male",
      language: "ru-RU",
    },
    {
      value: "ru-RU-Standard-E",
      label: "Ekaterina",
      gender: "female",
      language: "ru-RU",
    },
    {
      value: "ru-RU-Wavenet-A",
      label: "Natalia",
      gender: "female",
      language: "ru-RU",
    },
    {
      value: "ru-RU-Wavenet-B",
      label: "Sergei",
      gender: "male",
      language: "ru-RU",
    },
    {
      value: "ru-RU-Wavenet-C",
      label: "Olga",
      gender: "female",
      language: "ru-RU",
    },
    {
      value: "ru-RU-Wavenet-D",
      label: "Mikhail",
      gender: "male",
      language: "ru-RU",
    },
    {
      value: "ru-RU-Wavenet-E",
      label: "Tatiana",
      gender: "female",
      language: "ru-RU",
    },
    {
      value: "sr-RS-Standard-A",
      label: "Jelena",
      gender: "female",
      language: "sr-RS",
    },
    {
      value: "sk-SK-Standard-A",
      label: "Mária",
      gender: "female",
      language: "sk-SK",
    },
    {
      value: "sk-SK-Wavenet-A",
      label: "Peter",
      gender: "male",
      language: "sk-SK",
    },
    {
      value: "es-ES-Neural2-A",
      label: "Sofía",
      gender: "female",
      language: "es-ES",
    },
    {
      value: "es-ES-Neural2-B",
      label: "Alejandro",
      gender: "male",
      language: "es-ES",
    },
    {
      value: "es-ES-Neural2-C",
      label: "Elena",
      gender: "female",
      language: "es-ES",
    },
    {
      value: "es-ES-Neural2-D",
      label: "Isabel",
      gender: "female",
      language: "es-ES",
    },
    {
      value: "es-ES-Neural2-E",
      label: "María",
      gender: "female",
      language: "es-ES",
    },
    {
      value: "es-ES-Neural2-F",
      label: "Alejandro",
      gender: "male",
      language: "es-ES",
    },
    {
      value: "es-ES-Polyglot-1",
      label: "Javier",
      gender: "male",
      language: "es-ES",
    },
    {
      value: "es-ES-Standard-A",
      label: "Carmen",
      gender: "female",
      language: "es-ES",
    },
    {
      value: "es-ES-Standard-B",
      label: "Juan",
      gender: "male",
      language: "es-ES",
    },
    {
      value: "es-ES-Standard-C",
      label: "Ana",
      gender: "female",
      language: "es-ES",
    },
    {
      value: "es-ES-Standard-D",
      label: "Laura",
      gender: "female",
      language: "es-ES",
    },
    {
      value: "es-ES-Wavenet-B",
      label: "Carlos",
      gender: "male",
      language: "es-ES",
    },
    {
      value: "es-ES-Wavenet-C",
      label: "Sofía",
      gender: "female",
      language: "es-ES",
    },
    {
      value: "es-ES-Wavenet-D",
      label: "Eva",
      gender: "female",
      language: "es-ES",
    },
    {
      value: "es-US-Neural2-A",
      label: "Gabriela",
      gender: "female",
      language: "es-US",
    },
    {
      value: "es-US-Neural2-B",
      label: "Luis",
      gender: "male",
      language: "es-US",
    },
    {
      value: "es-US-Neural2-C",
      label: "Diego",
      gender: "male",
      language: "es-US",
    },
    {
      value: "es-US-News-D",
      label: "Javier",
      gender: "male",
      language: "es-US",
    },
    {
      value: "es-US-News-E",
      label: "Diego",
      gender: "male",
      language: "es-US",
    },
    {
      value: "es-US-News-F",
      label: "Gabriela",
      gender: "female",
      language: "es-US",
    },
    {
      value: "es-US-News-G",
      label: "Luisa",
      gender: "female",
      language: "es-US",
    },
    {
      value: "es-US-Polyglot-1",
      label: "Roberto",
      gender: "male",
      language: "es-US",
    },
    {
      value: "es-US-Standard-A",
      label: "Maria",
      gender: "female",
      language: "es-US",
    },
    {
      value: "es-US-Standard-B",
      label: "Carlos",
      gender: "male",
      language: "es-US",
    },
    {
      value: "es-US-Standard-C",
      label: "Alejandro",
      gender: "male",
      language: "es-US",
    },
    {
      value: "es-US-Studio-B",
      label: "David",
      gender: "male",
      language: "es-US",
    },
    {
      value: "es-US-Wavenet-A",
      label: "Sofia",
      gender: "female",
      language: "es-US",
    },
    {
      value: "es-US-Wavenet-B",
      label: "Miguel",
      gender: "male",
      language: "es-US",
    },
    {
      value: "es-US-Wavenet-C",
      label: "Juan",
      gender: "male",
      language: "es-US",
    },
    {
      value: "sv-SE-Standard-A",
      label: "Emma",
      gender: "female",
      language: "sv-SE",
    },
    {
      value: "sv-SE-Standard-B",
      label: "Elsa",
      gender: "female",
      language: "sv-SE",
    },
    {
      value: "sv-SE-Standard-C",
      label: "Olivia",
      gender: "female",
      language: "sv-SE",
    },
    {
      value: "sv-SE-Standard-D",
      label: "Gustav",
      gender: "male",
      language: "sv-SE",
    },
    {
      value: "sv-SE-Standard-E",
      label: "Oscar",
      gender: "male",
      language: "sv-SE",
    },
    {
      value: "sv-SE-Wavenet-A",
      label: "Elin",
      gender: "female",
      language: "sv-SE",
    },
    {
      value: "sv-SE-Wavenet-B",
      label: "Ebba",
      gender: "female",
      language: "sv-SE",
    },
    {
      value: "sv-SE-Wavenet-C",
      label: "Erik",
      gender: "male",
      language: "sv-SE",
    },
    {
      value: "sv-SE-Wavenet-D",
      label: "Sara",
      gender: "female",
      language: "sv-SE",
    },
    {
      value: "sv-SE-Wavenet-E",
      label: "Axel",
      gender: "male",
      language: "sv-SE",
    },
    {
      value: "ta-IN-Standard-A",
      label: "Ananya",
      gender: "female",
      language: "ta-IN",
    },
    {
      value: "ta-IN-Standard-B",
      label: "Arjun",
      gender: "male",
      language: "ta-IN",
    },
    {
      value: "ta-IN-Standard-C",
      label: "Chitra",
      gender: "female",
      language: "ta-IN",
    },
    {
      value: "ta-IN-Standard-D",
      label: "Deepak",
      gender: "male",
      language: "ta-IN",
    },
    {
      value: "ta-IN-Wavenet-A",
      label: "Aaradhya",
      gender: "female",
      language: "ta-IN",
    },
    {
      value: "ta-IN-Wavenet-B",
      label: "Bhuvan",
      gender: "male",
      language: "ta-IN",
    },
    {
      value: "ta-IN-Wavenet-C",
      label: "Chandana",
      gender: "female",
      language: "ta-IN",
    },
    {
      value: "ta-IN-Wavenet-D",
      label: "Dinesh",
      gender: "male",
      language: "ta-IN",
    },
    {
      value: "te-IN-Standard-A",
      label: "Anusha",
      gender: "female",
      language: "te-IN",
    },
    {
      value: "te-IN-Standard-B",
      label: "Bhaskar",
      gender: "male",
      language: "te-IN",
    },
    {
      value: "th-TH-Neural2-C",
      label: "Nong",
      gender: "female",
      language: "th-TH",
    },
    {
      value: "th-TH-Standard-A",
      label: "Sirima",
      gender: "female",
      language: "th-TH",
    },
    {
      value: "tr-TR-Standard-A",
      label: "Aylin",
      gender: "female",
      language: "tr-TR",
    },
    {
      value: "tr-TR-Standard-B",
      label: "Barış",
      gender: "male",
      language: "tr-TR",
    },
    {
      value: "tr-TR-Standard-C",
      label: "Canan",
      gender: "female",
      language: "tr-TR",
    },
    {
      value: "tr-TR-Standard-D",
      label: "Defne",
      gender: "female",
      language: "tr-TR",
    },
    {
      value: "tr-TR-Standard-E",
      label: "Emir",
      gender: "male",
      language: "tr-TR",
    },
    {
      value: "tr-TR-Wavenet-A",
      label: "Ayşe",
      gender: "female",
      language: "tr-TR",
    },
    {
      value: "tr-TR-Wavenet-B",
      label: "Berk",
      gender: "male",
      language: "tr-TR",
    },
    {
      value: "tr-TR-Wavenet-C",
      label: "Ceren",
      gender: "female",
      language: "tr-TR",
    },
    {
      value: "tr-TR-Wavenet-D",
      label: "Deniz",
      gender: "female",
      language: "tr-TR",
    },
    {
      value: "tr-TR-Wavenet-E",
      label: "Efe",
      gender: "male",
      language: "tr-TR",
    },
    {
      value: "uk-UA-Standard-A",
      label: "Anastasiya",
      gender: "female",
      language: "uk-UA",
    },
    {
      value: "uk-UA-Wavenet-A",
      label: "Viktoriya",
      gender: "female",
      language: "uk-UA",
    },
    {
      value: "vi-VN-Neural2-A",
      label: "An",
      gender: "female",
      language: "vi-VN",
    },
    {
      value: "vi-VN-Neural2-D",
      label: "Duy",
      gender: "male",
      language: "vi-VN",
    },
    {
      value: "vi-VN-Standard-A",
      label: "Anh",
      gender: "female",
      language: "vi-VN",
    },
    {
      value: "vi-VN-Standard-B",
      label: "Bình",
      gender: "male",
      language: "vi-VN",
    },
    {
      value: "vi-VN-Standard-C",
      label: "Cẩm",
      gender: "female",
      language: "vi-VN",
    },
    {
      value: "vi-VN-Standard-D",
      label: "Đạt",
      gender: "male",
      language: "vi-VN",
    },
    {
      value: "vi-VN-Wavenet-A",
      label: "Anh",
      gender: "female",
      language: "vi-VN",
    },
    {
      value: "vi-VN-Wavenet-B",
      label: "Bảo",
      gender: "male",
      language: "vi-VN",
    },
    {
      value: "vi-VN-Wavenet-C",
      label: "Cát",
      gender: "female",
      language: "vi-VN",
    },
    {
      value: "vi-VN-Wavenet-D",
      label: "Đức",
      gender: "male",
      language: "vi-VN",
    },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioPlayer, setAudioPlayer] = useState(null);
  const [DefaultLanguage, setDefaultLanguage] = useState("No");
  const [error, setError] = useState(null);
  const [selectedVoiceAPI, setSelectedVoiceAPI] = useState(null);

  useEffect(() => {
    console.log("Audio source updated:", audioSrc);
    if (audioSrc) {
      const audio = new Audio(audioSrc);
      setAudioPlayer(audio);
    }
  }, [audioSrc]);

  const handleLanguageChange = (selectedOption) => {
    console.log("Language selected:", selectedOption);
    setSelectedLanguage(selectedOption);
  };

  const handleGenderButtonClick = (gender) => {
    console.log("Gender selected:", gender);
    setSelectedGender(gender);
  };

  const handleVoiceChange = (selectedOption) => {
    if (selectedOption) {
      console.log("Voice selected:", selectedOption);
      setSelectedVoiceAPI(selectedOption.value);
      const audioFileName = selectedOption.value + ".wav"; // Assuming audio files have .wav extension
      const audioPath = require(`../../assets/audio/${audioFileName}`);
      console.log("Audio path:", audioPath);
      setAudioSrc(audioPath);
    }
  };

  const handlePlayButtonClick = () => {
    console.log("Play button clicked");
    if (audioPlayer) {
      audioPlayer.play();
    }
  };

  const handleStopButtonClick = () => {
    console.log("Stop button clicked");
    if (audioPlayer) {
      audioPlayer.pause();
      audioPlayer.currentTime = 0;
    }
  };

  const filteredVoiceOptions = VoiceSelection.filter(
    (voice) =>
      voice.language === (selectedLanguage ? selectedLanguage.value : null) &&
      voice.gender === selectedGender
  );

  const handleDefaultLanguage = (DefaultLanguageValue) => {
    console.log("Select Language:", DefaultLanguageValue);
    setDefaultLanguage(parseInt(DefaultLanguageValue));
    // Do something with the clicked value
  };

  const handleApiCall = () => {
    // Make API call using selected values
    const data = {
      language: selectedLanguage.value,
      voice: selectedVoiceAPI,
      gender: selectedGender,
      default_lang: DefaultLanguage,
    };

    console.log("Data: ", data)

    const token = localStorage.getItem('access_token');
    const headers = {
      Authorization: `Bearer ${token}`
    };

    // Example API call using axios
    axios
      .post(`${backendURL}/add-language-audio`, data, { headers })
      .then((response) => {
        console.log("API Response:", response.data);
        setError(response.data.message);
      })
      .catch((error) => {
        // console.error("Login failed:", error.response);
        if (error.response) {
          // console.error("Login failed with status code:", error.response.status);
          // console.error("Response data:", error.response.data);
          setError(error.response.data.message);
        } else if (error.request) {
          // The request was made but no response was received
          // console.error("No response received:", error.request);
          setError("No response received from API.", error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            // console.error("Error setting up request:", error.message);
            setError("Error setting up request", error.message);
        }
      });
  };

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle">
              {" "}
              <strong>Language and</strong> Audio
            </h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">
                        Select Website Language
                      </h5>
                      <form>
                        <Select
                          id="LanguageSelectionSelect"
                          options={LanguageSelection}
                          onChange={handleLanguageChange}
                          value={selectedLanguage}
                        />
                        <br />
                        <h5 className="card-title mb-1">Select Gender</h5>
                        <div
                          id="selectGender"
                          className="btn-group btn-group-lg mb-3"
                          role="group"
                          aria-label="Large button group"
                        >
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => handleGenderButtonClick("male")}
                          >
                            Male
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => handleGenderButtonClick("female")}
                          >
                            Female
                          </button>
                        </div>
                        <br />
                        <h5 className="card-title mb-1">Select Voice</h5>
                        {/* <Select
                                    id="VoiceSelectionSelect"
                                    options={filteredVoiceOptions}
                                  /> */}

                        <div className="row">
                          <div className="col-11 col-lg-11">
                            <Select
                              id="VoiceSelectionSelect"
                              options={filteredVoiceOptions}
                              onChange={handleVoiceChange}
                            />
                          </div>

                          <div
                            className="col-1 col-lg-1"
                            style={{ paddingLeft: "0px" }}
                          >
                            <button
                              type="button"
                              className="btn btn-secondary btn-play-on"
                              onClick={handlePlayButtonClick}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-play align-middle me-2"
                              >
                                <polygon points="5 3 19 12 5 21 5 3"></polygon>
                              </svg>
                            </button>
                            {/* <button type="button" className="btn btn-secondary" onClick={handleStopButtonClick}>Stop</button> */}
                          </div>
                        </div>

                        <br />
                        <h5 className="card-title mb-1">
                          Select Default Language
                        </h5>
                        <div
                          id="DefaultLanguageID"
                          className="btn-group btn-group-lg mb-3"
                          role="group"
                          aria-label="Large button group"
                        >
                          <button type="button" className="btn btn-secondary" onClick={() => handleDefaultLanguage("1")}>
                            Yes
                          </button>
                          <button type="button" className="btn btn-secondary" onClick={() => handleDefaultLanguage("0")}>
                            No
                          </button>
                        </div>

                        <br />
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={handleApiCall}
                        >
                          Save
                        </button>
                      </form>
                    </div>                    
                  </div>
                  <br/ >
                  <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>                    
                </div>
              </div>
            </div>
          </div>
          {/* Show the audio language lists  */}
          <ListAudioLanguage />
        </div>
      </main>
    </div>
  );
}

export default AudioLanguage;
