import React, { useState, useEffect } from "react";
import axios from "axios";

export default function ChatHistory() {

    useEffect(() => {
        document.title = 'Chat History | KlonIT Web Application';
    }, []);

    return (
        <div>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="mb-3">
                        <h1 className="h3 d-inline align-middle"> <strong>Chat</strong> History</h1>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">
                                        History of old chats
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div className="page-chat-history">
                                        <section className="section-chat-history">
                                            <div className="">

                                                <div className="row">

                                                    <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
                                                        
                                                        <div className="card">
                                                            <div className="card-body">

                                                                <ul className="list-unstyled mb-0">
                                                                    <li className="p-2 border-bottom chat-bg">
                                                                        <a href="#!" className="d-flex justify-content-between">
                                                                            <div className="d-flex flex-row">
                                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-8.webp" alt="avatar"
                                                                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="40" />
                                                                                <div className="pt-1">
                                                                                    <p className="fw-bold mb-0">John Doe</p>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-1">
                                                                                <p className="small text-muted mb-1">Just now</p>
                                                                                <span className="badge bg-danger float-end">1</span>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="p-2 border-bottom">
                                                                        <a href="#!" className="d-flex justify-content-between">
                                                                            <div className="d-flex flex-row">
                                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-1.webp" alt="avatar"
                                                                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="40" />
                                                                                <div className="pt-1">
                                                                                    <p className="fw-bold mb-0">Danny Smith</p>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-1">
                                                                                <p className="small text-muted mb-1">5 mins ago</p>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="p-2 border-bottom">
                                                                        <a href="#!" className="d-flex justify-content-between">
                                                                            <div className="d-flex flex-row">
                                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-2.webp" alt="avatar"
                                                                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="40" />
                                                                                <div className="pt-1">
                                                                                    <p className="fw-bold mb-0">Alex Steward</p>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-1">
                                                                                <p className="small text-muted mb-1">Yesterday</p>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="p-2 border-bottom">
                                                                        <a href="#!" className="d-flex justify-content-between">
                                                                            <div className="d-flex flex-row">
                                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-3.webp" alt="avatar"
                                                                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="40" />
                                                                                <div className="pt-1">
                                                                                    <p className="fw-bold mb-0">Ashley Olsen</p>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-1">
                                                                                <p className="small text-muted mb-1">Yesterday</p>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="p-2 border-bottom">
                                                                        <a href="#!" className="d-flex justify-content-between">
                                                                            <div className="d-flex flex-row">
                                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-4.webp" alt="avatar"
                                                                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="40" />
                                                                                <div className="pt-1">
                                                                                    <p className="fw-bold mb-0">Kate Moss</p>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-1">
                                                                                <p className="small text-muted mb-1">Yesterday</p>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="p-2 border-bottom">
                                                                        <a href="#!" className="d-flex justify-content-between">
                                                                            <div className="d-flex flex-row">
                                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp" alt="avatar"
                                                                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="40" />
                                                                                <div className="pt-1">
                                                                                    <p className="fw-bold mb-0">Lara Croft</p>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-1">
                                                                                <p className="small text-muted mb-1">Yesterday</p>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    <li className="p-2">
                                                                        <a href="#!" className="d-flex justify-content-between">
                                                                            <div className="d-flex flex-row">
                                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp" alt="avatar"
                                                                                    className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="40" />
                                                                                <div className="pt-1">
                                                                                    <p className="fw-bold mb-0">Brad Pitt</p>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            <div className="pt-1">
                                                                                <p className="small text-muted mb-1">5 mins ago</p>
                                                                                <span className="text-muted float-end"><i className="fas fa-check" aria-hidden="true"></i></span>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                </ul>

                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="col-md-6 col-lg-7 col-xl-8">

                                                        <ul className="list-unstyled">
                                                            <li className="d-flex justify-content-between mb-4">
                                                                <div className="card w-100">
                                                                    {/* <div className="card-header d-flex justify-content-between p-3">
                                                                    <p className="fw-bold mb-0">Lara Croft</p>
                                                                    <p className="text-muted small mb-0"><i className="far fa-clock"></i> 13 mins ago</p>
                                                                    </div> */}
                                                                    <div className="card-body">
                                                                        <p className="mb-0">
                                                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                                                            laudantium.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp" alt="avatar"
                                                                    className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong" width="40" />
                                                            </li>
                                                            <li className="d-flex justify-content-between mb-4">
                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp" alt="avatar"
                                                                    className="rounded-circle d-flex align-self-start me-3 shadow-1-strong" width="40" />
                                                                <div className="card">

                                                                    <div className="card-body">
                                                                        <p className="mb-0">
                                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                            labore et dolore magna aliqua.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="d-flex justify-content-between mb-4">
                                                                <div className="card w-100">
                                                                    {/* <div className="card-header d-flex justify-content-between p-3">
                                                                    <p className="fw-bold mb-0">Lara Croft</p>
                                                                    <p className="text-muted small mb-0"><i className="far fa-clock"></i> 13 mins ago</p>
                                                                    </div> */}
                                                                    <div className="card-body">
                                                                        <p className="mb-0">
                                                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                                                            laudantium.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp" alt="avatar"
                                                                    className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong" width="40" />
                                                            </li>

                                                            <li className="d-flex justify-content-between mb-4">
                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-6.webp" alt="avatar"
                                                                    className="rounded-circle d-flex align-self-start me-3 shadow-1-strong" width="40" />
                                                                <div className="card">

                                                                    <div className="card-body">
                                                                        <p className="mb-0">
                                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                                                            labore et dolore magna aliqua.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li className="d-flex justify-content-between mb-4">
                                                                <div className="card w-100">
                                                                    {/* <div className="card-header d-flex justify-content-between p-3">
                                                                    <p className="fw-bold mb-0">Lara Croft</p>
                                                                    <p className="text-muted small mb-0"><i className="far fa-clock"></i> 13 mins ago</p>
                                                                    </div> */}
                                                                    <div className="card-body">
                                                                        <p className="mb-0">
                                                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                                                                            laudantium.
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/avatar-5.webp" alt="avatar"
                                                                    className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong" width="40" />
                                                            </li>
                                                            {/* <li className="bg-white mb-3">
                                        <div className="form-outline">
                                        <textarea className="form-control" id="textAreaExample2" rows="4"></textarea>
                                        <label className="form-label" for="textAreaExample2">Message</label>
                                        </div>
                                                            </li>
                                                            <button type="button" className="btn btn-info btn-rounded float-end">Send</button> */}
                                                        </ul>

                                                    </div>

                                                </div>

                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </main>
        </div>
    );
}
