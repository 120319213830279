import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Dashboard() {
  useEffect(() => {
    document.title = "Dashboard | KlonIT Web Application";
  }, []);

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb-3">
            <h1 className="h3 d-inline align-middle">
              <strong>Dashboard</strong>
            </h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="page-Dashboard">
                    <section className="section-Dashboard">
                      <div className="">
                        <div className="row">
                          <div className="col-md-12 col-lg-12 col-xl-12 website-list-group">
                            <div className="list-group">
                              <Link to="#" className="list-group-item">
                                <div className="row g-0 align-items-center">
                                  
                                  <div className="col-12 ps-2">
                                    <div className="text-dark">Welcome to KlonIT Chatbot</div>
                                    <div className="text-muted mt-1">
                                    Answer Questions & Close Sales On Any Website, Blog or Ecom Store!
                                    </div>                                    
                                  </div>
                                </div>
                              </Link>
                            </div>                            
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
