import React, { useState, useEffect } from "react";
import axios from "axios";
import DataTable from 'react-data-table-component';
import backendURL from "../../config";

export default function ListDocuments({refresh}) {
  useEffect(() => {
    document.title = 'Uploaded Files | KlonIT Web Application';
    fetchDocuments();
  }, [refresh]);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Function to fetch documents from the API
  const fetchDocuments = async () => {
    try {
      const token = localStorage.getItem('access_token'); 
      const headers = {
        Authorization: `Bearer ${token}`
      };

      const response = await axios.get(`${backendURL}/get-private-data`, { headers });
      setDocuments(response.data);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("Authentication error:", error.response.data.error);
        setError("Authentication error:", error.response.data.error);
      } else {
        console.error("Error fetching documents:", error);
        setError("Error fetching documents:", error);
      }
    }
  };

  const handleDelete = async (documentId) => {
    try {
      const token = localStorage.getItem('access_token');
      const headers = {
        Authorization: `Bearer ${token}`
      };

      await axios.delete(`${backendURL}/delete-private-data/${documentId}`, { headers });
      setDocuments(documents.filter(doc => doc.id !== documentId));
      setError("Document has been deleted successfully.");
    } catch (error) {
      console.error("Error deleting document:", error);
      setError("Error deleting document:", error);
    }
  };

  // Define columns for the DataTable
  const columns = [
    {
      name: 'File Name',
      selector: row => row.filename,
      sortable: true,
    },
    {
      name: 'Page Content',
      selector: row => row.page_content,
      sortable: true,
      maxWidth: '315px',
    },
    {
      name: 'Is Scrape?',
      selector: row => row.is_scrape ? "Yes" : "No",
      sortable: true,
      hide: 'md' // Hide column on smaller screens
    },
    {
      name: 'Is Trained?',
      selector: row => row.is_trained ? "Yes" : "No",
      sortable: true,
      omit: false,
    },
    {
      name: 'Upload Date',
      selector: row => row.upload_date,
      sortable: true,
      hide: 'md' // Hide column on smaller screens
    },
    {
      name: 'Action',
      cell: row => (
        <button className="deleteIcon" onClick={() => handleDelete(row.id)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path></svg>
        </button>
      )
    }
  ];

  const ExpandedComponent = ({ data }) => (
    <div>
      <p><strong>File Name:</strong> {data.filename}</p>
      <p><strong>Page Content:</strong> {data.page_content}</p>
    </div>
  );
  

  return (
    <div className="row">
      <div className="col-12 col-lg-12 col-xxl-12 d-flex">
        <div className="card flex-fill">
          <div className="card-header">
            {/* <h5 className="card-title mb-0">Uploaded Files</h5> */}
          </div>
          <div className="card-body">
            {loading ? (
              <p>Loading...</p>
            ) : documents.length === 0 ? (
              <p>No data available</p>
            ) : (
              <DataTable
                columns={columns}
                data={documents}
                pagination
                responsive                
                highlightOnHover
                pointerOnHover
                expandableRows
                expandableRowsComponent={({ data }) => <ExpandedComponent data={data} />}
                expandOnRowClicked
              />
            )}
            <br/>
            <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
            <br/>
          </div>
        </div>
      </div>
    </div>
  );
}
