import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "react-select";
import axios from "axios";
import backendURL from "../config";

const CountrySelection = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Åland Islands", label: "Åland Islands" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  { value: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cape Verde", label: "Cape Verde" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Congo, The Democratic Republic of The", label: "Congo, The Democratic Republic of The" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Cote D'ivoire", label: "Cote D'ivoire" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  { value: "French Southern Territories", label: "French Southern Territories" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-bissau", label: "Guinea-bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Heard Island and Mcdonald Islands", label: "Heard Island and Mcdonald Islands" },
  { value: "Holy See (Vatican City State)", label: "Holy See (Vatican City State)" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran, Islamic Republic of", label: "Iran, Islamic Republic of" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea, Democratic People's Republic of", label: "Korea, Democratic People's Republic of" },
  { value: "Korea, Republic of", label: "Korea, Republic of" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Lao People's Democratic Republic", label: "Lao People's Democratic Republic" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Macedonia, The Former Yugoslav Republic of", label: "Macedonia, The Former Yugoslav Republic of" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia, Federated States of", label: "Micronesia, Federated States of" },
  { value: "Moldova, Republic of", label: "Moldova, Republic of" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestinian Territory, Occupied", label: "Palestinian Territory, Occupied" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  { value: "Reunion", label: "Reunion" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Helena", label: "Saint Helena" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  { value: "Saint Vincent and The Grenadines", label: "Saint Vincent and The Grenadines" },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Georgia and The South Sandwich Islands", label: "South Georgia and The South Sandwich Islands" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { value: "Taiwan (ROC)", label: "Taiwan (ROC)" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania, United Republic of", label: "Tanzania, United Republic of" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-leste", label: "Timor-leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Virgin Islands, British", label: "Virgin Islands, British" },
  { value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
  { value: "Wallis and Futuna", label: "Wallis and Futuna" },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" }
  ];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {

  useEffect(() => {
    document.title = 'User Profile | KlonIT Web Application';
    fetchData();
  }, []);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [UserProfile, setUserProfile] = useState("");
  const [UserfName, setUserfName] = useState("");
  const [UserlName, setUserlName] = useState("");
  const [UserEmail, setUserEmail] = useState("");
  const [UserAddress, setUserAddress] = useState("");
  const [UserCountry, setUserCountry] = useState("");
  const [UserCity, setUserCity] = useState("");
  const [UserState, setUserState] = useState("");
  const [UserZip, setUserZip] = useState("");
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const token = localStorage.getItem("access_token");
  const headers = {
    Authorization: `Bearer ${token}`
  };

  const fetchData = () => {
    axios
      .get(`${backendURL}/get-user-profile`, { headers })
      .then((response) => {
        if (response.data.length > 0) {
          const latestConfig = response.data[response.data.length - 1];
          setUserProfile(latestConfig.profile_image);
          setUserfName(latestConfig.fname);
          setUserlName(latestConfig.lname);
          setUserEmail(latestConfig.email);
          setUserAddress(latestConfig.address_one);
          setUserCountry(latestConfig.country);
          setUserCity(latestConfig.city);
          setUserState(latestConfig.state);
          setUserZip(latestConfig.zip);
        }
      })
      .catch((error) => {
        setMessage("Error fetching for User Profile:", error);
      });
  };

  const handleCountryChange = (selectedOption) => {
    setUserCountry(selectedOption ? selectedOption.value : "");
  };

  const getDefaultCountryOption = () => {
    return CountrySelection.find(option => option.value === UserCountry);
  };

  const handleSaveButtonClick = async () => {
    if (selectedFile) {
      // Check if the selected file is an image
      const validExtensions = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!validExtensions.includes(selectedFile.type)) {
        setMessage("Invalid file type. Only JPG, JPEG, and PNG are allowed.");
        return;
      }
  
      // Create a new FormData object to send the file to the server
      const formData = new FormData();
      formData.append('avatar', selectedFile);
  
      // Append additional data to the FormData object
      formData.append('fname', UserfName);
      formData.append('lname', UserlName);
      formData.append('address_one', UserAddress);
      formData.append('country', UserCountry);
      formData.append('city', UserCity);
      formData.append('state', UserState);
      formData.append('zip', UserZip);
  
      try {
        // Send the image and additional data to the server
        const response = await axios.post(`${backendURL}/update-user-profile`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        setMessage(response.data.message);
      } catch (error) {
        setMessage("Error updating user profile.");
        console.error(error);
      }
    } else {
      // If no file is selected, just update the user profile with the existing data
      const data = {
        fname: UserfName,
        lname: UserlName,
        address_one: UserAddress,
        country: UserCountry,
        city: UserCity,
        state: UserState,
        zip: UserZip,
        profile_image: UserProfile,
      };
  
      axios
        .post(`${backendURL}/update-user-profile`, data, { headers })
        .then((response) => {
          setMessage(response.data.message);
        })
        .catch((error) => {
          if (error.response) {
            setMessage(error.response.data.message);
          } else if (error.request) {
            setMessage(error.request);
          } else {
            setMessage(error.message);
          }
        });
    }
  };
  

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        height: 224,
      }}
    >
      <div>
        <main className="content">
          <div className="container-fluid p-0">
            <div className="mb-3">
              <h1 className="h3 d-inline align-middle">
                <strong>User</strong> Profile
              </h1>
            </div>
            <div className="row">
              <div className="col-md-3 col-xl-2">
                <div className="card">
                  <div className="card-header">
                    <h5 className="card-title mb-0 text-center">Profile Settings</h5>
                  </div>

                  <div className="list-group list-group-flush" role="tablist">
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={value}
                      onChange={handleChange}
                      aria-label="Vertical tabs example"
                      sx={{ borderRight: 1, borderColor: "divider" }}
                    >
                      <Tab label="Account" {...a11yProps(0)} />
                      <Tab label="Password" {...a11yProps(1)} />
                    </Tabs>
                  </div>
                </div>
              </div>

              <div className="col-md-9 col-xl-10">
                <div className="tab-content">
                  <TabPanel value={value} index={0}>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Account Settings</h5>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-8">
                            <div className="row">
                              <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="inputFirstName">
                                  First name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputFirstName"
                                  value={UserfName}
                                  onChange={(e) => setUserfName(e.target.value)}
                                />
                              </div>
                              <div className="mb-3 col-md-6">
                                <label className="form-label" htmlFor="inputLastName">
                                  Last name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="inputLastName"
                                  value={UserlName}
                                  onChange={(e) => setUserlName(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="inputEmail">
                                Email
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                id="inputEmail"
                                value={UserEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="inputAddress">
                                Address
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="inputAddress"
                                value={UserAddress}
                                onChange={(e) => setUserAddress(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="inputCountry">
                                Country
                              </label>
                              <Select
                                id="inputCountry"
                                options={CountrySelection}
                                value={getDefaultCountryOption()}
                                onChange={handleCountryChange}
                                isClearable
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="inputCity">
                                City
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="inputCity"
                                value={UserCity}
                                onChange={(e) => setUserCity(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="inputState">
                                State
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="inputState"
                                value={UserState}
                                onChange={(e) => setUserState(e.target.value)}
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label" htmlFor="inputZip">
                                Zip Code
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="inputZip"
                                value={UserZip}
                                onChange={(e) => setUserZip(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="text-center">
                              <img
                                alt="User Avatar"
                                src={UserProfile}
                                className="rounded-circle img-responsive mt-2"
                                width="128"
                                height="128"
                              />
                              <div className="mt-2">
                                <input
                                  type="file"
                                  accept=".jpg,.jpeg,.png"
                                  onChange={handleFileChange}
                                />
                              </div>
                              <small>
                                For best results, use an image at least 128px by 128px in .jpg, .jpeg, or .png format.
                              </small>
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-primary" onClick={handleSaveButtonClick}>
                          Save changes
                        </button>
                        <br/>
                        <br/>
                        <div id="getError" className={`alert alert-info ${message ? "" : "d-none"}`}>{message}</div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <div className="card">
                      <div className="card-header">
                        <h5 className="card-title mb-0">Password</h5>
                      </div>
                      <div className="card-body">
                        <form>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="inputPasswordCurrent">
                              Current password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="inputPasswordCurrent"
                            />
                            <small>
                              <a href="#">Forgot your password?</a>
                            </small>
                          </div>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="inputPasswordNew">
                              New password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="inputPasswordNew"
                            />
                          </div>
                          <div className="mb-3">
                            <label className="form-label" htmlFor="inputPasswordNew2">
                              Verify password
                            </label>
                            <input
                              type="password"
                              className="form-control"
                              id="inputPasswordNew2"
                            />
                          </div>
                          <button type="submit" className="btn btn-primary">
                            Save changes
                          </button>
                        </form>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Box>
  );
}
