import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatDistanceToNow } from 'date-fns';
import backendURL from "../config";

export default function ChatHistory() {
    const [activeChat, setActiveChat] = useState(""); // State variable to keep track of the active chat
    const [documents, setDocuments] = useState([]); // State variable to store fetched chat data
    const [loading, setLoading] = useState(true); // State variable to track loading state
    const [error, setError] = useState(null); // State variable to store error message

    useEffect(() => {
        document.title = 'Chat History | KlonIT Web Application';
        fetchDocuments();
    }, []);

    // Function to fetch documents from the API
    const fetchDocuments = async () => {
        try {
            const token = localStorage.getItem('access_token'); // Assuming you're storing the token in localStorage
            // Set the headers with the token
            const headers = {
                Authorization: `Bearer ${token}`
            };

            const response = await axios.get(`${backendURL}/get-chat-history`, { headers });
            setDocuments(response.data);
            setLoading(false);
            console.log("Get Data: ", response.data)
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Handle authentication errors, e.g., redirect to login page
                console.error("Authentication error:", error.response.data.error);
                setError("Authentication error:", error.response.data.error);
            } else {
                console.error("Error fetching data:", error);
                setError("Error fetching data:", error);
            }
        }
    };

    const handleChatClick = (chatId) => {
        setActiveChat(chatId); // Set the active chat when a chat link is clicked
    };

    // Function to format the date to relative format
    const formatRelativeDate = (dateString) => {
        if (dateString === 'N/A' || !dateString) {
            return 'Invalid date';
        }
        const date = new Date(dateString);
        if (isNaN(date)) {
            return 'Invalid date';
        }
        return formatDistanceToNow(date, { addSuffix: true });
    };

    return (
        <div>
            <main className="content">
                <div className="container-fluid p-0">
                    <div className="mb-3">
                        <h1 className="h3 d-inline align-middle"> <strong>Chat</strong> History</h1>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="card">
                                <div className="card-header">
                                    {/* <h5 className="card-title mb-0">
                                        History of old chats
                                    </h5> */}
                                </div>
                                <div className="card-body">
                                    <div className="page-chat-history">
                                        <section className="section-chat-history">
                                            <div className="">
                                                <div className="row">
                                                    <div className="col-md-6 col-lg-5 col-xl-4 mb-4 mb-md-0">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <ul className="list-unstyled mb-0">
                                                                    {/* Map through the fetched chat data to generate chat links */}
                                                                    {Object.keys(documents).map(chatId => (
                                                                        <li key={chatId} className={`p-2 border-bottom chat-bg ${activeChat === chatId ? "active" : ""}`}>
                                                                            <a href="#!" className="d-flex justify-content-between" aria-controls={chatId} onClick={() => handleChatClick(chatId)}>
                                                                                <div className="d-flex flex-row">
                                                                                    {/* Assuming the avatar and last activity are available in the fetched data */}
                                                                                    <img src="images/avatars/avatar.jpg" alt="avatar" className="rounded-circle d-flex align-self-center me-3 shadow-1-strong" width="40" />
                                                                                    <div className="pt-1">
                                                                                        <p className="fw-bold mb-0">{chatId}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="pt-1">
                                                                                    {/* Format the last activity date */}
                                                                                    <p className="small text-muted mb-1">{formatRelativeDate(documents[chatId][0].created_date)}</p>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-7 col-xl-8">
                                                        {activeChat && (
                                                            <div id={activeChat} className="tab-detailed active">
                                                                <ul className="list-unstyled">
                                                                    {/* Render the chat messages corresponding to the active chat */}
                                                                    {documents[activeChat].map((message, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <li className="d-flex justify-content-between mb-4">
                                                                                <div className="card w-100">
                                                                                    <div className="card-body">
                                                                                        <p className="mb-0">{message.questions}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <img src="images/avatars/avatar.jpg" alt="avatar" className="rounded-circle d-flex align-self-start ms-3 shadow-1-strong" width="40" />
                                                                            </li>
                                                                            <li className="d-flex justify-content-between mb-4">
                                                                                <img src="images/avatars/avatar_img (1).png" alt="avatar" className="rounded-circle d-flex align-self-start me-3 shadow-1-strong" width="40" />
                                                                                <div className="card">
                                                                                    <div className="card-body">
                                                                                        <p className="mb-0">{message.answers}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        </React.Fragment>
                                                                    ))}

                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
