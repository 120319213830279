import React, { useState, useEffect } from "react";
import axios from "axios";
import backendURL from "../../config";
import ListWebsites from './ListWebsites';
import ListScrapeData from './ListScrapeData';
import ListTrainedData from './ListTrainedData';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

export default function AddWebsite() {

  useEffect(() => {
    document.title = 'Add Website | KlonIT Web Application';
  }, []);

  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [refresh, setRefresh] = useState(false); // State to trigger refresh

  const urlRegex = /^(https?:\/\/)?([\w.]+)\.([a-z]{2,})(\/[\w .-]*)*\/?$/i;

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setRefresh((prev) => !prev); // Trigger refresh when tab changes
  };

  const handleAddWebBtnClick = async () => {
    if (urlRegex.test(inputValue)) {
      try {
        const token = localStorage.getItem('access_token');
        const headers = {
          Authorization: `Bearer ${token}`
        };

        await axios.post(`${backendURL}/add-website-link`, { website_link: inputValue }, { headers });
        setInputValue(""); // Clear the input after adding
        setError("Website link has been added successfully.");
        setRefresh((prev) => !prev); // Trigger refresh after adding a new link
      } catch (error) {
        console.error("Error adding website link:", error);
        setError("Error adding website link:", error);
      }
    } else {
      alert("Please enter a valid website link.");
    }
  };

  return (
    <div>
      <main className="content">
        <div className="container-fluid p-0">
          <div className="mb3">
            <h1 className="h3 d-inline align-middle"><strong>Add Website</strong> Links</h1>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-1">
                    Add your website links. (Link should be started with https:// or www)
                  </h5>
                  <div className="row">
                    <div className="col-4 col-lg-4">
                      <div className="file-loading">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Input"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                        <br />
                        <button
                          className="btn btn-primary"
                          onClick={handleAddWebBtnClick}
                        >
                          Add Website Link
                        </button>

                        <br />
                        <br />
                        <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>
                          {error}
                        </div> {/* Display error message */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Tabs for ListWebsites and ListScrapeData */}
          <Box sx={{ width: '100%' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="website and scrape data tabs">
              <Tab label="Unscraped Data" />
              <Tab label="Scraped Data" />
              <Tab label="Trained Data" />
            </Tabs>
            <Box sx={{ p: 3 }}>
              {tabValue === 0 && <ListWebsites refresh={refresh} setRefresh={setRefresh} />}
              {tabValue === 1 && <ListScrapeData refresh={refresh} setRefresh={setRefresh} />}
              {tabValue === 2 && <ListTrainedData refresh={refresh} setRefresh={setRefresh} />}
            </Box>
          </Box>

        </div>
      </main>
    </div>
  );
}
