import React, { useState } from "react";
import axios from "axios";
import backendURL from "../../config";

export default function EditImproveQuestion({ selectedItem, onSave, onCancel }) {

  const [editedItem, setEditedItem] = useState({ ...selectedItem });
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedItem({ ...editedItem, [name]: value });
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem("access_token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      await axios.put(`${backendURL}/edit-improve-question/${editedItem.id}`, editedItem, { headers });

      // Call onSave function provided by parent component
      onSave();

      setError("Data has been saved successfully.");

    } catch (error) {
      console.error("Error updating data:", error);
      setError("Error updating data:", error);
      
    }
  };

  return (
    <div>

<div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title mb-0"></h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">User Question</h5>
                      <div className="">
                      <input className="form-control" type="text" name="questions" value={editedItem.questions} onChange={handleInputChange} />
                      </div>
                    </div>
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">Bot Answer</h5>
                      <div className="">
                        <input className="form-control" type="text" name="answers" value={editedItem.answers} onChange={handleInputChange} />
                      </div>
                    </div>
                    <div className="col-4 col-lg-4">
                      <h5 className="card-title mb-1">Improve Your Question</h5>
                      <div className="">
                        <input className="form-control" type="text" name="question_improvement" value={editedItem.question_improvement} onChange={handleInputChange} />
                      </div>
                    </div>
                  </div>
                  <br/>
                  
                  <div className="row">
                    <div className="col-12 col-lg-12">
                      <br />
                      <button className="btn btn-primary mx-1" onClick={handleSave}>Save</button>
                      <button className="btn btn-primary" onClick={onCancel}>Cancel</button>
                    </div>
                    <div className="col-12 col-lg-12">                    
                      <br/>
                      <div id="getError" className={`alert alert-warning ${error ? "" : "d-none"}`}>{error}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      {/* Render your form with input fields */}
      
      
      {/* Other input fields */}
      
      
    </div>
  );
}
